import React, { useState, useEffect, useRef, Fragment } from "react";
import PropTypes from "prop-types";

import { get } from "utils/DeApi";

import RequirementCreate from "../RequirementCreate/RequirementCreate";
import RequirementUpdate from "../RequirementUpdate/RequirementUpdate";

const Requirement = ({ question, jurisdiction }) => {
  const subscribedPromises = useRef([]);
  const [requirements, setRequirements] = useState([]);

  useEffect(() => {
    const fetchRequirements = () => {
      const requirementsPromise = get(
        `topic-questions/${question.id}/regulatory-requirements`,
        { params: { jurisdictionId: jurisdiction.id } }
      );
      requirementsPromise.promise
        .then((response) => {
          setRequirements(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) console.warn(error);
        });
      subscribedPromises.current.push(requirementsPromise);
    };

    fetchRequirements();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [question, jurisdiction]);

  const handleRequirementUpdated = (requirement) => {
    setRequirements((requirements) => [
      requirement,
      ...requirements.filter((rq) => rq.id !== requirement.id),
    ]);
  };

  const handleRequirementCreated = (requirement) => {
    setRequirements((requirements) => [requirement, ...requirements]);
  };

  return (
    <Fragment>
      {requirements?.length ? (
        requirements.map((requirement) => (
          <Fragment key={requirement.id}>
            <td>
              <p>{requirement.response}</p>
              <div className="flex justify-between mb-0">
                <span className="flex-none border text-gray-600 text-xs tracking-wide font-semibold px-2 py-1 rounded-md">
                  Updated on{" "}
                  {new Date(requirement.updatedAt).toLocaleString([], {
                    dateStyle: "short",
                  })}
                  &nbsp; at &nbsp;
                  {new Date(requirement.updatedAt).toLocaleString([], {
                    timeStyle: "short",
                  })}
                </span>

                <RequirementUpdate
                  requirement={requirement}
                  jurisdiction={jurisdiction}
                  question={question}
                  onRequirementUpdated={handleRequirementUpdated}
                />
              </div>
            </td>
          </Fragment>
        ))
      ) : (
        <Fragment>
          <td className="text-nowrap text-end" colSpan={2}>
            <RequirementCreate
              jurisdiction={jurisdiction}
              question={question}
              onRequirementCreated={handleRequirementCreated}
            />
          </td>
        </Fragment>
      )}
    </Fragment>
  );
};
Requirement.propTypes = {
  question: PropTypes.object.isRequired,
  jurisdiction: PropTypes.object.isRequired,
};

export default Requirement;
