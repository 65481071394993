import React from "react";
import { Container } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import Footer from "components/App/Footer/Footer";
import Header from "../App/Header/Header";
import ProducerDashboard from "./ProducerDashboard/ProducerDashboard";
import ProducerDetails from "./ProducerDetails/ProducerDetails";
import ProducerJurisdiction from "./ProducerJurisdiction/ProducerJurisdiction";

const Producer = () => {
  return (
    <>
      <Header />
      <Container fluid className="MainContent">
        <Routes>
          <Route index element={<ProducerDashboard />} />
          <Route path="/:producerId" element={<ProducerDetails />} />
          <Route
            path="/:producerId/jurisdictions/:jurisdictionId/*"
            element={<ProducerJurisdiction />}
          />
        </Routes>
      </Container>
      <Footer />
    </>
  );
};

Producer.propTypes = {};

export default Producer;
