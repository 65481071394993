import React, { createContext, useState, useEffect, useRef } from "react";
import { get } from "utils/BeeApi";
import { get as getDE } from "utils/DeApi";

import Loader from "components/Loader/Loader";

const UserContext = createContext(undefined);
const UserDispatchContext = createContext(undefined);

function UserProvider({ children }) {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState();
  const subscribedPromises = useRef([]);

  useEffect(() => {
    setIsLoading(true);
    const userPromise = get("user/anon");

    userPromise.promise
      .then((response) => {
        // let user = response.data
        // user.isAdmin = user.roles.length > 0;
        // user.isProducer = !user.isAdmin

        // setUser(user);

        const userPromiseDE = getDE("login");
        subscribedPromises.current.push(userPromiseDE);
        return userPromiseDE.promise;
      })
      .then((response) => {
        let user = response.data;
        let adminSubscriberRole = user.roles.find(
          (item) => item.name === "admin subscriber"
        );
        user.isAdmin =
          (adminSubscriberRole &&
            adminSubscriberRole.hasOwnProperty("roleId")) ||
          false;
        user.isProducer = !user.isAdmin;

        setUser(user);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          localStorage.removeItem("b3JnYW5pemF0aW9u");
          setUser(null);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(userPromise);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  if (isLoading) return <Loader />;
  if (user === undefined) return <span />;
  return (
    <UserContext.Provider value={user}>
      <UserDispatchContext.Provider value={setUser}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
}

export { UserProvider, UserContext, UserDispatchContext };
