import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Row, Col, Alert, Card, ListGroup } from "react-bootstrap";
import { get } from "utils/DeApi";
import LegislationCreate from "./LegislationCreate/LegislationCreate";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import LegislationUpdate from "./LegislationUpdate/LegislationUpdate";
import LegislationDelete from "./LegislationDelete/LegislationDelete";
import "./JurisdictionLegislation.css";

const JurisdictionLegislations = ({ topics, jurisdiction }) => {
  const subscribedPromises = useRef([]);

  const [legislations, setLegislations] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const params = useParams();
  const topicId = params.topicId;
  const topic = topics.find((item) => item.id === topicId);

  useEffect(() => {
    const fetchLegislations = () => {
      setError(null);
      setIsLoading(true);
      const legislationsPromise = get(
        `jurisdictions/${jurisdiction.id}/legislations`,
        { params: { topicId: topic.id } }
      );
      legislationsPromise.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);
          setLegislations(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(legislationsPromise);
    };

    fetchLegislations();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [jurisdiction, topic]);

  const handleLegislationDeleted = (legislation) => {
    setLegislations((legislations) => [
      ...legislations.filter((lg) => lg.id !== legislation.id),
    ]);
  };

  const handleLegislationUpdated = (legislation) => {
    setLegislations((legislations) => [
      legislation,
      ...legislations.filter((lg) => lg.id !== legislation.id),
    ]);
  };

  const handleLegislationCreated = (legislation) => {
    setLegislations((legislations) => [legislation, ...legislations]);
  };

  return (
    <Row>
      <Col xs={12} className="py-4">
        <span className="float-end">
          <LegislationCreate
            topic={topic}
            jurisdiction={jurisdiction}
            onLegislationCreated={handleLegislationCreated}
          />
        </span>

        <h3>{topic.name} Legislations</h3>
        <hr />

        <Card>
          <Card.Body>
            {error && <ErrorHandler error={error} />}

            {isLoading ? (
              <Loader />
            ) : legislations && legislations.length > 0 ? (
              <ListGroup variant="flush">
                {legislations.map((legislation) => (
                  <ListGroup.Item key={legislation.id}>
                    <div className="flex pb-1 justify-between">
                      <div className="w-70">
                        <a
                          className="d-flex no-underline font-semibold"
                          rel="noreferrer"
                          target="_blank"
                          href={legislation.link}
                        >
                          {legislation.name}
                          {"  "}
                          <span className="material-icons-outlined md-16 pt-1">
                            open_in_new
                          </span>
                        </a>
                      </div>
                      <div>
                        <LegislationUpdate
                          legislation={legislation}
                          onLegislationUpdated={handleLegislationUpdated}
                        />{" "}
                        <LegislationDelete
                          legislation={legislation}
                          onLegislationDeleted={handleLegislationDeleted}
                        />
                      </div>
                    </div>
                    <p>{legislation.notes}</p>
                    <div className="flex space-x-2">
                      <span className="flex-none border text-gray-600 text-xs tracking-wide font-semibold px-2 py-1 rounded-md">
                        Updated on{" "}
                        {new Date(legislation.updatedAt).toLocaleString([], {
                          dateStyle: "short",
                        })}
                        &nbsp; at &nbsp;
                        {new Date(legislation.updatedAt).toLocaleString([], {
                          timeStyle: "short",
                        })}
                      </span>
                      {legislation.reviewScheduled ? (
                        <span className="flex-none border text-gray-600 text-xs tracking-wide font-semibold px-2 py-1 rounded-md">
                          Review Scheduled At{" "}
                          {new Date(legislation.reviewScheduled).toLocaleString(
                            [],
                            {
                              dateStyle: "short",
                            }
                          )}
                        </span>
                      ) : (
                        <span className="flex-none border text-gray-600 text-xs tracking-wide font-semibold px-2 py-1 rounded-md">
                          No Review Scheduled
                        </span>
                      )}
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <Alert variant="info">No legislations found.</Alert>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

JurisdictionLegislations.propTypes = {
  jurisdiction: PropTypes.object.isRequired,
  topics: PropTypes.array.isRequired,
};

export default JurisdictionLegislations;
