import React, { useEffect, useState, useRef } from "react";
import Header from "components/App/Header/Header";
import {
  Button,
  Col,
  Container,
  Nav,
  Row,
  Collapse,
  Card,
  Alert,
  ListGroup,
} from "react-bootstrap";
import { get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { Route, Routes, Link, useLocation, useParams } from "react-router-dom";
import TopicDetails from "./TopicDetails/TopicDetails";
import SectionDetails from "./SectionDetails/SectionDetails";
import "./Topic.scss";
import { arrayMove } from "@dnd-kit/sortable";
import Footer from "components/App/Footer/Footer";

const Topic = () => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [topics, setTopics] = useState();
  const [activeTopicId, setActiveTopicId] = useState();

  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    const fetchTopics = () => {
      setError(null);
      setIsLoading(true);
      const topicsPromise = get("topics");
      topicsPromise.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);
          setTopics(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(topicsPromise);
    };

    fetchTopics();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  useEffect(() => {
    const topicId = getTopicIdFromUrl(params);
    setActiveTopicId(topicId);
  }, [params]);

  const getTopicIdFromUrl = (params) => {
    let str = params["*"];
    if (str === "") return null;

    if (str.includes("/")) return str.split("/")[0];

    return str;
  };

  const handleSectionsReordered = (oldIndex, newIndex) => {
    setTopics((topics) => {
      let activeTopic = topics.find((item) => item.id === activeTopicId);
      let updatedTopicSections = arrayMove(
        activeTopic.sections,
        oldIndex,
        newIndex
      );
      activeTopic.sections = updatedTopicSections;

      let dt = topics.map((topic) => {
        return topic.id === activeTopicId ? activeTopic : topic;
      });

      return dt;
    });
  };

  const handleSectionCreated = (section) => {
    setTopics((topics) => {
      let activeTopic = topics.find((item) => item.id === activeTopicId);
      let updatedTopicSections = [...activeTopic.sections, section];
      activeTopic.sections = updatedTopicSections;

      let dt = topics.map((topic) => {
        return topic.id === activeTopicId ? activeTopic : topic;
      });

      return dt;
    });
  };

  const handleSectionDeleted = (section) => {
    setTopics((topics) => {
      let activeTopic = topics.find((item) => item.id === activeTopicId);
      let updatedTopicSections = [
        ...activeTopic.sections.filter((sec) => sec.id !== section.id),
      ];
      activeTopic.sections = updatedTopicSections;

      let dt = topics.map((topic) => {
        return topic.id === activeTopicId ? activeTopic : topic;
      });

      return dt;
    });
  };

  const handleSectionUpdated = (section) => {
    setTopics((topics) => {
      let activeTopic = topics.find((item) => item.id === activeTopicId);

      let updatedTopicSections = [
        ...activeTopic.sections.map((sec) =>
          sec.id === section.id ? section : sec
        ),
      ];
      activeTopic.sections = updatedTopicSections;

      let dt = topics.map((topic) => {
        return topic.id === activeTopicId ? activeTopic : topic;
      });

      return dt;
    });
  };

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!topics) return <span />;

  return (
    <>
      <Header />
      <Container className="MainContent">
        <Row>
          <Col xs={12} md={4} className="scroller py-4">
            <h3>Topics</h3>
            <p>
              <Link to="/topics" className="text-decoration-none">
                Overview
              </Link>
            </p>

            <hr />

            <Nav
              className="flex-column space-y-2"
              variant="pills"
              activeKey={location?.pathname}
            >
              {topics.map((topic) => (
                <Nav.Item key={topic.id} className="">
                  <Nav.Link
                    as={Link}
                    to={`/topics/${topic.id}`}
                    href={`/topics/${topic.id}`}
                    data-bs-toggle="collapse"
                    aria-expanded={activeTopicId === topic.id ? true : false}
                    aria-controls="home-collapse"
                    // className={
                    //   activeTopicId === topic.id ? "active2" : "text-black"
                    // }
                    className="btn-toggle font-semibold text-lg before:mr-2"
                  >
                    {topic.name}
                  </Nav.Link>
                  <Collapse in={activeTopicId === topic.id}>
                    <div id="home-collapse">
                      <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                        {topic.sections.length > 0 &&
                          topic.sections.map((section) => {
                            return (
                              <li key={section.id} className="">
                                <Nav.Link
                                  as={Link}
                                  to={`/topics/${topic.id}/sections/${section.id}`}
                                  href={`/topics/${topic.id}/sections/${section.id}`}
                                >
                                  {section.name}
                                </Nav.Link>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </Collapse>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col xs={12} md={8}>
            <Routes>
              <Route
                index
                element={
                  <>
                    <div className="my-4">
                      <Card>
                        <Card.Body>
                          <h3>Topics</h3>
                          <hr className="" />

                          {error && <ErrorHandler error={error} />}

                          {isLoading ? (
                            <Loader />
                          ) : topics && topics.length > 0 ? (
                            <ListGroup variant="flush">
                              {topics.map((topic) => (
                                <ListGroup.Item key={topic.id} className="">
                                  <span className="float-end text-center ms-2">
                                    <Button
                                      as={Link}
                                      to={`/topics/${topic.id}`}
                                      size="sm"
                                      variant="primary"
                                    >
                                      Manage
                                    </Button>
                                  </span>
                                  <h4>
                                    <Link
                                      to={`/topics/${topic.id}`}
                                      className="text-decoration-none"
                                    >
                                      {topic.name}
                                    </Link>
                                  </h4>
                                  <p>{topic.description}</p>
                                  <p>
                                    <span className="flex-none mt-2 border text-gray-600 text-xs tracking-wide px-2 py-1 rounded-md">
                                      Updated on{" "}
                                      {new Date(topic.updatedAt).toLocaleString(
                                        [],
                                        {
                                          dateStyle: "short",
                                        }
                                      )}
                                      &nbsp; at &nbsp;
                                      {new Date(topic.updatedAt).toLocaleString(
                                        [],
                                        {
                                          timeStyle: "short",
                                        }
                                      )}
                                    </span>
                                  </p>
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          ) : (
                            <Alert variant="info">
                              No topics found at this time.
                            </Alert>
                          )}
                        </Card.Body>
                      </Card>
                    </div>
                  </>
                }
              />
              <Route
                path=":topicId"
                element={
                  <TopicDetails
                    key={location.pathname}
                    topics={topics}
                    onSectionsOrdered={handleSectionsReordered}
                    onSectionCreated={handleSectionCreated}
                    onSectionDeleted={handleSectionDeleted}
                    onSectionUpdated={handleSectionUpdated}
                  />
                }
              />
              <Route
                path=":topicId/sections/:sectionId"
                element={<SectionDetails />}
              />
            </Routes>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Topic;
