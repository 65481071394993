import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row, ListGroup, Breadcrumb } from "react-bootstrap";

import { get } from "utils/DeApi";
import { get as beeGet } from "utils/BeeApi";

const ProducerJurisdictionOverview = ({ producer, jurisdiction, topics }) => {
  const subscribedPromises = useRef([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNews, setIsLoadingNews] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState();
  const [ermContents, setErmContents] = useState([]);
  const [ermContentSpecificInfo, setContentSpecificInfo] = useState([]);

  useEffect(() => {
    const getContents = () => {
      setIsLoadingNews(true);
      const categoryId = process.env.REACT_APP_CONTENT_CATEGORY_ID;
      const jurisdictionContentsPromise = beeGet(
        `search/contents?q=categories.category_id:${categoryId}`
      );

      jurisdictionContentsPromise.promise
        .then((response) => {
          const refinedData = response.data
            .filter((content) => content.listed === true)
            .map(({ contentId }) => ({
              contentId,
            }));
          setErmContents(refinedData);
          setIsLoadingNews(false);
        })
        .catch((error) => {
          setError(error);
          setIsLoadingNews(false);
        });
    };

    getContents();

    const fetchReviews = (topic) => {
      const reviewsPromise = get(`jurisdictions/${jurisdiction.id}/reviews`, {
        params: { topicId: topic.id },
      });
      setError(null);
      setIsLoading(true);

      reviewsPromise.promise
        .then((response) => {
          setError(null);
          setIsLoading(false);
          setReviews((reviews) => [...reviews, ...response.data]);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(reviewsPromise);
    };

    topics.map((topic) => fetchReviews(topic));
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [jurisdiction, topics]);

  useEffect(() => {
    const getContentSpecificInfo = () => {
      ermContents.forEach((ermContent) => {
        const jurisdictionSpecificContentPromise = beeGet(
          `contents/${ermContent?.contentId}`
        );

        jurisdictionSpecificContentPromise.promise
          .then((response) => {
            setContentSpecificInfo((prevState) => [
              ...prevState,
              response?.data,
            ]);
          })
          .catch((error) => {
            setError(error);
          });
      });
    };

    getContentSpecificInfo();
  }, [ermContents]);

  useEffect(() => {
    const sortErmSpecificInfo = () => {
      ermContentSpecificInfo.sort((a, b) =>
        !!b.contentExternalLink
          ? a.contentExternalLink > b.contentExternalLink
            ? 1
            : -1
          : true
      );
    };
    sortErmSpecificInfo();
  }, [ermContentSpecificInfo]);

  return (
    <Row>
      <Col xs={12} md={8} className="mt-3 mb-5">
        <Breadcrumb className="">
          <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item href={`/producers/${producer?.id}`}>
            {producer?.name}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{jurisdiction?.title}</Breadcrumb.Item>
        </Breadcrumb>

        <h2 className="font-semibold">{jurisdiction.title} Overview</h2>
        <span>
          Latest changes and updates to {jurisdiction.title} EPR regulatory
          landscape.
        </span>

        <hr className="" />

        <Card>
          <Card.Body>
            {error && <ErrorHandler error={error} />}
            {isLoading ? (
              <Loader />
            ) : (
              <ListGroup variant="flush">
                {reviews &&
                  reviews.map((review) => (
                    <ListGroup.Item key={review.id} className="px-0">
                      <p className="mb-1">{review.summary}</p>
                      <p className="flex justify-between mb-1">
                        <span className="flex-none mt-2 border text-gray-600 text-xs tracking-wide font-semibold px-2 py-1 rounded-md">
                          {review.topic.name}
                        </span>
                        <span className="flex-none mt-2 border text-gray-600 text-xs tracking-wide font-semibold px-2 py-1 rounded-md">
                          Updated at{" "}
                          {new Date(review.updatedAt).toLocaleString([], {
                            dateStyle: "short",
                            timeStyle: "short",
                          })}
                        </span>
                      </p>
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            )}
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} md={4} className="py-3 scroller">
        <div className="my-3">
          <h4 className="font-semibold">News</h4>
          <span>EPR-related news and articles</span>
          <hr />
          {isLoadingNews ? (
            <Loader />
          ) : (
            ermContentSpecificInfo &&
            ermContentSpecificInfo.map((ermArticle) =>
              ermArticle.contentExternalLink ? (
                <a
                  href={ermArticle?.contentExternalLink}
                  target="_blank"
                  rel="noreferrer"
                  key={ermArticle?.contentId}
                >
                  <p>
                    <div className="flex">
                      <div className="mr-1">
                        <span className="float-start material-icons-outlined md-20 me-2">
                          article
                        </span>
                      </div>
                      <div>{ermArticle?.title}</div>
                    </div>
                  </p>
                </a>
              ) : (
                <a
                  href={`/contents/${ermArticle.contentId}`}
                  rel="noreferrer"
                  key={ermArticle?.contentId}
                >
                  <p>
                    <div className="flex">
                      <div>
                        <span className="float-start material-icons-outlined md-20 me-2">
                          article
                        </span>
                      </div>
                      <div>{ermArticle?.title}</div>
                    </div>
                  </p>
                </a>
              )
            )
          )}
        </div>
      </Col>
    </Row>
  );
};

export default ProducerJurisdictionOverview;
