import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { useParams } from "react-router-dom";
import { Row, Col, Card, ListGroup, Alert } from "react-bootstrap";

import { get } from "utils/DeApi";
import ReviewCreate from "./ReviewCreate/ReviewCreate";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import ReviewUpdate from "./ReviewUpdate/ReviewUpdate";
import ReviewDelete from "./ReviewDelete/ReviewDelete";

const JurisdictionTopicReviews = ({ topics, jurisdiction }) => {
  const subscribedPromises = useRef([]);

  const [reviews, setReviews] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const params = useParams();
  const topicId = params.topicId;
  const topic = topics.find((item) => item.id === topicId);

  const fetchReviews = () => {
    setError(null);
    setIsLoading(true);
    const reviewsPromise = get(`jurisdictions/${jurisdiction.id}/reviews`, {
      params: { topicId: topic.id },
    });
    reviewsPromise.promise
      .then((response) => {
        setIsLoading(false);
        setError(null);
        setReviews(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(reviewsPromise);
  };

  useEffect(() => {
    const fetchReviews = () => {
      setError(null);
      setIsLoading(true);
      const reviewsPromise = get(`jurisdictions/${jurisdiction.id}/reviews`, {
        params: { topicId: topic.id },
      });
      reviewsPromise.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);
          setReviews(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(reviewsPromise);
    };

    fetchReviews();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [jurisdiction, topic]);

  return (
    <Row>
      <Col xs={12} className="py-4">
        <div>
          <span className="float-end">
            <ReviewCreate
              topic={topic}
              jurisdiction={jurisdiction}
              onReviewCreated={(review) =>
                setReviews((reviews) => [review, ...reviews])
              }
            ></ReviewCreate>
          </span>

          <h1>{topic.name}</h1>
          <p>Regulations Summary, Reviews and Updates</p>
          <hr className="" />

          <Card>
            <Card.Body className="">
              {error && <ErrorHandler error={error} />}

              {isLoading ? (
                <Loader />
              ) : reviews && reviews.length > 0 ? (
                <ListGroup variant="flush">
                  {reviews &&
                    reviews.map((review) => (
                      <ListGroup.Item key={review.id} className="px-0">
                        <div className="flex flex-row-reverse">
                          <span>
                            <ReviewUpdate
                              review={review}
                              onReviewUpdated={fetchReviews}
                            ></ReviewUpdate>{" "}
                            <ReviewDelete
                              review={review}
                              onReviewDeleted={fetchReviews}
                            />
                          </span>
                        </div>
                        <p className="mt-2">{review.summary}</p>
                        <div className="flex space-x-2">
                          <span className="flex-none border text-gray-600 text-xs tracking-wide font-semibold px-2 py-1 rounded-md">
                            Updated on{" "}
                            {new Date(review.updatedAt).toLocaleString([], {
                              dateStyle: "short",
                            })}
                            &nbsp; at &nbsp;
                            {new Date(review.updatedAt).toLocaleString([], {
                              timeStyle: "short",
                            })}
                          </span>
                        </div>
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              ) : (
                <Alert variant="info">
                  No reviews found for this jurisdiction.
                </Alert>
              )}
            </Card.Body>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

JurisdictionTopicReviews.propTypes = {
  topics: PropTypes.array.isRequired,
  jurisdiction: PropTypes.object.isRequired,
};
export default JurisdictionTopicReviews;
