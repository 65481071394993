import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";

import { put, get } from "utils/DeApi";
import { get as beeGet } from "utils/BeeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const ProducerUpdate = ({ producer, onProducerUpdated }) => {
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [jurisdictions, setJurisdictions] = useState([]);
  const [clients, setClients] = useState();
  const [producers, setProducers] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const availableProducers = producers.filter((prod) => {
    return prod.id !== producer.id;
  });

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(2, "Too Short!")
      .max(100, "Too Long!")
      .required()
      .notOneOf(
        availableProducers.map((prod) => prod.name),
        "A producer with the same name already exists"
      ),
    description: yup.string().nullable(),
    clientId: yup.string().required("Account name required"),
  });

  let selectedJurisdictions = producer.jurisdictions.map((jurisdiction) => {
    return { value: jurisdiction.id, label: jurisdiction.title };
  });

  useEffect(() => {
    const fetchProducers = () => {
      setError(null);
      setIsLoading(true);

      let producersPromise = get("producers");

      producersPromise.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);
          setProducers(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(producersPromise);
    };

    fetchProducers();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  useEffect(() => {
    const fetchClientCategories = () => {
      const groupId = process.env.REACT_APP_ACCOUNT_GROUP_ID;

      const categoriesPromise = beeGet("categories", {
        params: { group: groupId },
      });

      setError(null);
      setIsLoading(true);

      categoriesPromise.promise
        .then((response) => {
          setClients(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(categoriesPromise);
    };

    fetchClientCategories();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  useEffect(() => {
    const fetchAllJurisdictions = () => {
      const jurisdictionsPromise = get("jurisdictions");
      setError(null);
      setIsLoading(true);

      jurisdictionsPromise.promise
        .then((response) => {
          setIsLoading(false);
          let options = response.data.map((jurisdiction) => {
            return { value: jurisdiction.id, label: jurisdiction.title };
          });
          setJurisdictions(options);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(jurisdictionsPromise);
    };

    fetchAllJurisdictions();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  const updateProducer = ({ name, description, jurisdictions, clientId }) => {
    jurisdictions =
      jurisdictions.length > 0
        ? jurisdictions.map((jd) => {
            return jd.value;
          })
        : [];
    setError(null);
    setIsLoading(true);

    const producerPromise = put(`/producers/${producer.id}`, {
      name: name,
      description: description,
      jurisdictions: jurisdictions,
      clientId: clientId,
    });

    producerPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onProducerUpdated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(producerPromise);
  };

  return (
    <>
      <Button variant="secondary" size="sm" onClick={handleShow}>
        Update
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update Producer - {producer.name}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updateProducer(values)}
          initialValues={{
            name: producer.name,
            description: producer.description,
            jurisdictions: selectedJurisdictions,
            clientId: producer.clientId || "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form onKeyDown={(e) => e.stopPropagation()}>
              <Modal.Body>
                <Form.Group controlId="producerName" className="my-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.name}
                    isInvalid={errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="producerName" className="my-3">
                  <Form.Label>Account</Form.Label>
                  <Form.Select
                    name="clientId"
                    value={values.clientId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.clientId}
                    isInvalid={errors.clientId && touched.clientId}
                  >
                    <option value="">Select the account...</option>
                    {clients && clients.length > 0
                      ? clients.map((category) => (
                          <option
                            key={category.categoryId}
                            value={category.categoryId}
                          >
                            {category.categoryName}
                          </option>
                        ))
                      : ""}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.clientId}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="lens">
                  <Form.Label>Jurisdictions</Form.Label>
                  <Select
                    closeMenuOnSelect={false}
                    name="jurisdictions"
                    components={animatedComponents}
                    isMulti
                    defaultValue={[...selectedJurisdictions]}
                    onChange={(selectedOption) => {
                      values.jurisdictions = selectedOption;
                    }}
                    options={jurisdictions}
                    placeholder="Select applicable jurisdictions only..."
                  />
                </Form.Group>

                <Form.Group controlId="producerDescription" className="my-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.description}
                    isInvalid={errors.description && touched.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>

                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="secondary"
                  onClick={handleSubmit}
                  size="sm"
                  disabled={!isValid}
                >
                  Update
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ProducerUpdate;
