import React, { useEffect, useRef, useState } from "react";
import "./Content.scss";
import { Card, Col, Container, Row } from "react-bootstrap";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Header from "components/App/Header/Header";

import { get } from "utils/BeeApi";
import { get as beeGet } from "utils/BeeApi";
import Footer from "components/App/Footer/Footer";

const Content = () => {
  const contentId = window.location.pathname.split("/").pop();

  const [content, setContent] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [ermContents, setErmContents] = useState([]);
  const [ermContentSpecificInfo, setContentSpecificInfo] = useState([]);
  const subscribedPromises = useRef([]);

  useEffect(() => {
    const promises = subscribedPromises.current;
    fetchContent(contentId);
    getContents();
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, [contentId]);

  const getContents = () => {
    const categoryId = process.env.REACT_APP_CONTENT_CATEGORY_ID;
    const jurisdictionContentsPromise = beeGet(
      `search/contents?q=categories.category_id:${categoryId}`
    );
    setIsLoading(true);
    setError(null);

    jurisdictionContentsPromise.promise
      .then((response) => {
        const refinedData = response.data
          .filter((content) => content.listed === true)
          .map(({ contentId }) => ({
            contentId,
          }));
        setErmContents(refinedData);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(jurisdictionContentsPromise);
  };

  function fetchContent(id) {
    const contentDetailsPromise = get(`contents/${id}`, {});
    setIsLoading(true);
    setError(null);

    contentDetailsPromise.promise
      .then((response) => {
        setContent(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(contentDetailsPromise);
  }

  useEffect(() => {
    const getContentSpecificInfo = () => {
      ermContents.forEach((ermContent) => {
        const jurisdictionSpecificContentPromise = beeGet(
          `contents/${ermContent?.contentId}`
        );

        jurisdictionSpecificContentPromise.promise
          .then((response) => {
            setContentSpecificInfo((prevState) => [
              ...prevState,
              response.data,
            ]);
          })
          .catch((error) => {
            setError(error);
          });
      });
    };

    getContentSpecificInfo();
  }, [ermContents]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!content) return <span />;

  return (
    <>
      <Header />
      <Container className="py-5 MainContent">
        <Row>
          <Col xs={12} md={8} className="py-4">
            <Card className="">
              <Card.Body>
                <div>
                  <h1>{content.title}</h1>
                  <hr />
                  <div
                    className="ContentDetails"
                    dangerouslySetInnerHTML={{ __html: content.contentBody }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4} className="py-4">
            <div className="my-3">
              <h4 className="font-semibold">News</h4>
              <span>EPR-related news</span>
              <hr />
              {ermContentSpecificInfo &&
                ermContentSpecificInfo.map((ermArticle) =>
                  ermArticle.contentExternalLink ? (
                    <a
                      href={ermArticle?.contentExternalLink}
                      target="_blank"
                      rel="noreferrer"
                      key={ermArticle?.contentId}
                    >
                      <p>
                        <div className="flex">
                          <div>
                            <span className="float-start material-icons-outlined md-20 me-2">
                              article
                            </span>
                          </div>
                          <div>{ermArticle?.title}</div>
                        </div>
                      </p>
                    </a>
                  ) : (
                    <a
                      href={`/contents/${ermArticle.contentId}`}
                      rel="noreferrer"
                      key={ermArticle?.contentId}
                    >
                      <p>
                        <div className="flex">
                          <div>
                            <span className="float-start material-icons-outlined md-20 me-2">
                              article
                            </span>
                          </div>
                          <div>{ermArticle?.title}</div>
                        </div>
                      </p>
                    </a>
                  )
                )}
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Content;
