import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";

import { post, get } from "utils/DeApi";
import { get as beeGet } from "utils/BeeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const ProducerCreate = ({ producers, onProducerCreated }) => {
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [jurisdictions, setJurisdictions] = useState([]);
  const [clients, setClients] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(2, "Too Short!")
      .max(100, "Too Long!")
      .required()
      .notOneOf(
        producers.map((producer) => producer.name),
        "A producer with the same name already exists"
      ),
    description: yup.string(),
  });

  useEffect(() => {
    const fetchClientCategories = () => {
      const groupId = process.env.REACT_APP_ACCOUNT_GROUP_ID;

      const categoriesPromise = beeGet("categories", {
        params: { group: groupId },
      });

      setError(null);
      setIsLoading(true);

      categoriesPromise.promise
        .then((response) => {
          setClients(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(categoriesPromise);
    };

    fetchClientCategories();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  useEffect(() => {
    const fetchAllJurisdictions = () => {
      const jurisdictionsPromise = get("jurisdictions");
      setError(null);
      setIsLoading(true);

      jurisdictionsPromise.promise
        .then((response) => {
          setIsLoading(false);
          let options = response.data.map((jurisdiction) => {
            return { value: jurisdiction.id, label: jurisdiction.title };
          });
          setJurisdictions(options);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(jurisdictionsPromise);
    };

    fetchAllJurisdictions();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  const createProducer = ({ name, description, jurisdictions, clientId }) => {
    jurisdictions =
      jurisdictions.length > 0
        ? jurisdictions.map((jd) => {
            return jd.value;
          })
        : [];
    setError(null);
    setIsLoading(true);

    const producerPromise = post(`/producers`, {
      name: name,
      description: description,
      jurisdictions: jurisdictions,
      clientId: clientId,
    });

    producerPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onProducerCreated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(producerPromise);
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow} size="sm">
        New Producer
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>New Producer</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => createProducer(values)}
          initialValues={{
            name: "",
            clientId: "",
            description: "",
            jurisdictions: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="producerName" className="my-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.name}
                    isInvalid={errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="producerName" className="my-3">
                  <Form.Label>Account</Form.Label>
                  <Form.Select
                    name="clientId"
                    value={values.clientId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.clientId}
                    isInvalid={errors.clientId && touched.clientId}
                  >
                    <option value="">Select the account...</option>
                    {clients && clients.length > 0
                      ? clients.map((category) => (
                          <option
                            key={category.categoryId}
                            value={category.categoryId}
                          >
                            {category.categoryName}
                          </option>
                        ))
                      : ""}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.clientId}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="lens">
                  <Form.Label>Jurisdictions</Form.Label>
                  <Select
                    closeMenuOnSelect={false}
                    name="jurisdictions"
                    components={animatedComponents}
                    isMulti
                    onChange={(selectedOption) => {
                      values.jurisdictions = selectedOption;
                    }}
                    options={jurisdictions}
                    placeholder="Select applicable jurisdictions only..."
                  />
                </Form.Group>

                <Form.Group controlId="producerDescription" className="my-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.description}
                    isInvalid={errors.description && touched.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>

                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" size="sm" disabled={!isValid}>
                  Create Producer
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ProducerCreate;
