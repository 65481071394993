import React, { useRef, useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Table, Card, Alert } from "react-bootstrap";

import { get } from "utils/DeApi";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import SectionAssessment from "./SectionAssessment/SectionAssessment";

const JurisdictionAssessment = ({ topics, jurisdiction }) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [sections, setSections] = useState();

  const params = useParams();
  const topicId = params.topicId;
  const topic = topics.find((item) => item.id === topicId);

  useEffect(() => {
    const fetchSections = () => {
      setError(null);
      setIsLoading(true);
      const sectionsPromise = get(`topics/${topicId}/sections`);
      sectionsPromise.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);
          setSections(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(sectionsPromise);
    };
    fetchSections();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [topicId]);

  return (
    <Row>
      <Col xs={12} className="py-4">
        <Card>
          <Card.Body>
            <h4>{topic.name} Regulatory Requirements</h4>
            <hr className="mb-0" />

            {error && <ErrorHandler error={error} />}

            {isLoading ? (
              <Loader />
            ) : sections && sections.length > 0 ? (
              <Table hover>
                <thead>
                  <tr>
                    <th>Topic Question</th>
                    <th>Regulatory Requirement</th>
                  </tr>
                </thead>
                <tbody>
                  {sections.map((section) => (
                    <Fragment key={section.id}>
                      <tr>
                        <td
                          colSpan="4"
                          className="bg-primary uppercase font-semibold tracking-wide bg-primary bg-opacity-10 py-3"
                        >
                          {section.name}
                        </td>
                      </tr>
                      {
                        <SectionAssessment
                          section={section}
                          jurisdiction={jurisdiction}
                        />
                      }
                    </Fragment>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Alert variant="info">No regulatory requirements found.</Alert>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default JurisdictionAssessment;
