import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import React, { useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";
import { Table, Card } from "react-bootstrap";
import TopicQuestionDetails from "../../TopicQuestionDetails/TopicQuestionDetails";

const RegulatoryRequirementTab = ({ topic, jurisdiction }) => {
  const subscribedPromises = useRef([]);

  const [isLoading, setIsLoading] = useState(false);
  const [sections, setSections] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchSections = () => {
      let sectionsPromise = get(`topics/${topic.id}/sections`);

      sectionsPromise.promise
        .then((response) => {
          setIsLoading(false);
          setSections(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(fetchSections);
    };
    fetchSections();
  }, [topic]);

  return (
    <Card className="mb-5 border-t-0">
      <Card.Body className="">
        {error && <ErrorHandler error={error} />}
        {isLoading ? (
          <Loader />
        ) : (
          <Table hover className="border-t-0">
            <thead>
              <tr>
                <th className="w-1/2">Topic Question</th>
                <th className="w-1/2">Regulatory Requirement</th>
              </tr>
            </thead>
            <tbody>
              {sections.map((section) => (
                <TopicQuestionDetails
                  key={section.id}
                  section={section}
                  jurisdiction={jurisdiction}
                />
              ))}
            </tbody>
          </Table>
        )}
      </Card.Body>
    </Card>
  );
};

export default RegulatoryRequirementTab;
