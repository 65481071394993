import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Form, Alert } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as yup from "yup";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { post } from "utils/DeApi";

const AddTopicQuestion = ({ section, onCreated, isLink, questions }) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  const schema = yup.object().shape({
    prompt: yup
      .string()
      .min(2, "Too Short!")
      .max(1000, "Too Long!")
      .required()
      .notOneOf([questions], "Topic question exists"),
    sectionId: yup
      .string()
      .oneOf([section.id, null], "Must match the current section"),
    description: yup.string().max(1000, "Too Long!").nullable(),
    info: yup.string().nullable().min(5, "That id is too short!"),
  });

  const createTopicQuestion = (values) => {
    const createPromise = post(
      `sections/${section.id}/topic-questions`,
      values
    );
    setIsLoading(true);
    setError(null);

    createPromise.promise
      .then((response) => {
        setIsLoading(false);
        onCreated(response.data);
        handleClose();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      {isLink ? (
        <Alert.Link onClick={handleShow}>Create Topic Question</Alert.Link>
      ) : (
        <Button
          className="mb-2"
          variant="primary"
          size="sm"
          onClick={handleShow}
        >
          Add Topic Question
        </Button>
      )}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Topic Question</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => createTopicQuestion(values)}
          initialValues={{
            prompt: "",
            sectionId: section.id,
            description: "",
            info: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form onKeyDown={(e) => e.stopPropagation()}>
              <Modal.Body>
                <Form.Group className="mb-3" controlId="lens">
                  <Form.Label>Select Section</Form.Label>
                  <Field
                    as={Form.Select}
                    name="sectionId"
                    value={values.sectionId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.sectionId}
                    disabled={true}
                  >
                    <option value={section.id}>{section.name}</option>
                  </Field>
                  <Form.Control.Feedback type="invalid">
                    {errors.sectionId}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="prompt">
                  <Form.Label>Prompt</Form.Label>
                  <Form.Control
                    name="prompt"
                    value={values.prompt}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    as="textarea"
                    rows={3}
                    isValid={values.prompt}
                    isInvalid={errors.prompt && touched.prompt}
                  />
                  <Form.Text className="text-muted">
                    {1000 - values.prompt.length} characters left
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.prompt}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    as="textarea"
                    rows={3}
                    isValid={values.description}
                    isInvalid={errors.description && touched.description}
                  />
                  <Form.Text className="text-muted">
                    {1000 - values.description.length} characters left
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="info">
                  <Form.Label>Info</Form.Label>
                  <Form.Control
                    name="info"
                    value={values.info}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.info}
                    isInvalid={errors.info && touched.info}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.info}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>
              <Modal.Footer>
                <Button size="sm" variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  size="sm"
                  variant="primary"
                  disabled={!isValid}
                >
                  Create Topic Question
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

AddTopicQuestion.defaultProps = {
  isLink: false,
};

AddTopicQuestion.propTypes = {
  isLink: PropTypes.bool,
  section: PropTypes.object.isRequired,
};

export default AddTopicQuestion;
