import React, { useRef, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Col,
  Container,
  Row,
  Button,
  Alert,
  Table,
  Form,
  Card,
} from "react-bootstrap";
import { get } from "utils/DeApi";
import Header from "components/App/Header/Header";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import JurisdictionCreate from "components/Jurisdiction/JurisdictionCreate/JurisdictionCreate";
import "./Regulation.scss";
import Footer from "components/App/Footer/Footer";

const Regulation = () => {
  const subscribedPromises = useRef([]);

  const [jurisdictions, setJurisdictions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [filterParameters, setfilterParameters] = useState({});
  const [filteredJurisdictions, setFilteredJurisdictions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJurisdictions = () => {
      setError(null);
      setIsLoading(true);
      const jurisdictionsPromise = get("jurisdictions");
      jurisdictionsPromise.promise
        .then((response) => {
          setIsLoading(false);
          setJurisdictions(response?.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(jurisdictionsPromise);
    };

    fetchJurisdictions();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  useEffect(() => {
    const filterJurisdictions = () => {
      let dt = jurisdictions;

      if (filterParameters.title) {
        dt = dt.filter((jurisdiction) => {
          return (
            jurisdiction?.title
              .toLowerCase()
              .match(filterParameters.title.toLowerCase()) ||
            jurisdiction?.category
              .toLowerCase()
              .match(filterParameters.title.toLowerCase()) ||
            jurisdiction?.name
              .toLowerCase()
              .match(filterParameters.title.toLowerCase())
          );
        });
      }

      if (filterParameters.startDate) {
        dt = dt.filter((jurisdiction) => {
          return jurisdiction.updatedAt >= filterParameters.startDate;
        });
      }

      if (filterParameters.endDate) {
        dt = dt.filter((jurisdiction) => {
          return jurisdiction.updatedAt <= filterParameters.endDate;
        });
      }

      return dt;
    };

    setFilteredJurisdictions(filterJurisdictions());
  }, [filterParameters, jurisdictions]);

  const setFilterQuery = (event) => {
    event.preventDefault();
    const filterName = event.target.name;
    const value = event.target.value;

    setfilterParameters((prevState) => ({ ...prevState, [filterName]: value }));
  };

  const clearFilters = () => {
    setfilterParameters({});
  };

  return (
    <>
      <Header title="Regulations" />
      <Container className="MainContent">
        <div className="flex justify-between my-5">
          <div className="">
            <h1>Regulations</h1>
            <p className="mb-0 pb-0">
              EPR regulations by their corresponding geopolitical entities.
            </p>
          </div>
          <div className="flex-col mt-auto">
            <JurisdictionCreate
              jurisdictions={jurisdictions}
              onJurisdictionCreated={(jurisdiction) =>
                navigate(`/jurisdictions/${jurisdiction.id}`)
              }
            />
          </div>
        </div>

        <hr />

        <Row className="py-3">
          <Col md={3}>
            <Form.Group>
              <Form.Label className="control-label font-semibold">
                Search...
              </Form.Label>
              <input
                className="form-control"
                type="search"
                placeholder="Search by keyword..."
                aria-label="Search"
                value={filterParameters?.title || ""}
                name="title"
                onChange={(event) => setFilterQuery(event)}
              />
            </Form.Group>
          </Col>
          <Col md={3} className="">
            <Form.Group className="">
              <Form.Label className="control-label font-semibold">
                Date
              </Form.Label>
              <input
                className="form-control"
                type="date"
                name="startDate"
                onChange={(event) => setFilterQuery(event)}
                value={filterParameters?.startDate || ""}
              />
            </Form.Group>
          </Col>
          <Col md={6} className="flex-col mt-auto">
            <Button
              name="clearFilter"
              className="float-right"
              size="sm"
              variant="primary"
              onClick={clearFilters}
            >
              Clear Filters
            </Button>
          </Col>
        </Row>

        <Card>
          <Card.Body>
            <div className="border-bottom mb-2">
              <span className="tracking-wide text-xl pt-2">Jurisdictions</span>
            </div>

            {error && <ErrorHandler error={error} />}

            {isLoading ? (
              <Loader />
            ) : filteredJurisdictions.length === 0 ? (
              <Alert variant="info">
                No Jurisdiction Matches the search creteria
              </Alert>
            ) : (
              <Table hover>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Name</th>
                    <th>Category</th>
                    <th>Last Update</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredJurisdictions.map((jurisdiction) => (
                    <tr key={jurisdiction.id}>
                      <td>{jurisdiction.title}</td>
                      <td>{jurisdiction.name}</td>
                      <td>
                        <span className="flex-none bg-green-200 text-gray-600 text-xs tracking-wide font-semibold px-2 py-1 rounded-md">
                          {jurisdiction.category.charAt(0).toUpperCase() +
                            jurisdiction.category.slice(1)}
                        </span>
                      </td>
                      <td>
                        <span className="flex-none border text-gray-600 text-xs tracking-wide font-semibold px-2 py-1 rounded-md">
                          {new Date(jurisdiction.updatedAt).toLocaleString([], {
                            dateStyle: "short",
                            timeStyle: "short",
                          })}
                        </span>
                      </td>
                      <td>
                        <Button
                          size="sm"
                          variant="outline-primary"
                          as={Link}
                          to={`/jurisdictions/${jurisdiction.id}`}
                        >
                          <span className="material-icons-outlined md-18">
                            tune
                          </span>{" "}
                          Manage
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
      </Container>
      <Footer />
    </>
  );
};

export default Regulation;
