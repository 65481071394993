import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import React, { useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";
import { Card } from "react-bootstrap";
import "./SummaryTab.css";

const SummaryTab = ({ topic, jurisdiction }) => {
  const subscribedPromises = useRef([]);

  const [isLoading, setIsLoading] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    setError(null);
    setIsLoading(true);
    const fetchReviews = () => {
      const reviewsPromise = get(`jurisdictions/${jurisdiction.id}/reviews`, {
        params: { topicId: topic.id },
      });
      reviewsPromise.promise
        .then((response) => {
          setError(null);
          setIsLoading(false);
          setReviews(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(reviewsPromise);
    };

    fetchReviews();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [jurisdiction, topic]);

  return (
    <Card className="mb-5 border-t-0">
      <Card.Body>
        {error && <ErrorHandler error={error} />}
        {isLoading ? (
          <Loader />
        ) : reviews && reviews.length > 0 ? (
          reviews.map((review) => (
            <div className="py-3" key={review.id}>
              <p className="">{review.summary}</p>
              <p className="text-end">
                <span className="flex-none mt-2 border text-gray-600 text-xs tracking-wide font-semibold px-2 py-1 rounded-md">
                  Updated on{" "}
                  {new Date(review.updatedAt).toLocaleString([], {
                    dateStyle: "short",
                  })}
                  &nbsp; at &nbsp;
                  {new Date(review.updatedAt).toLocaleString([], {
                    timeStyle: "short",
                  })}
                </span>
              </p>
            </div>
          ))
        ) : (
          <div className="alert alert-warning">
            There is currently nothing to show here
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default SummaryTab;
