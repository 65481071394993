import React from "react";
import PropTypes from "prop-types";
import { ListGroup, ButtonGroup } from "react-bootstrap";
import UpdateSection from "../UpdateSection/UpdateSection";
import DeleteSection from "../DeleteSection/DeleteSection";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import "./DraggableSection.scss";

const DraggableSection = ({
  section,
  onSectionUpdated,
  onSectionDeleted,
  className,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: section.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: isDragging ? "#f1f5f9" : "",
  };

  return (
    <ListGroup.Item
      id="DraggableItem"
      className={className ? className : ""}
      ref={setNodeRef}
      style={style}
    >
      <div className="d-flex justify-content-between">
        <span className="pt-1">
          <span
            className="DragHandle material-icons-outlined text-gray-400 hover:cursor-move mr-4"
            {...listeners}
            {...attributes}
          >
            drag_indicator
          </span>
          <span className="">{section.name}</span>
        </span>
        <ButtonGroup aria-label="Basic example" size="sm">
          <UpdateSection section={section} onUpdate={onSectionUpdated} />
          <DeleteSection section={section} onDelete={onSectionDeleted} />
        </ButtonGroup>
      </div>
    </ListGroup.Item>
  );
};

DraggableSection.propTypes = {
  section: PropTypes.object.isRequired,
  onSectionUpdated: PropTypes.func.isRequired,
  onSectionDeleted: PropTypes.func.isRequired,
};

export default DraggableSection;
