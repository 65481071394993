import React, { useEffect, useRef, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Col,
  Row,
  Button,
  Alert,
  ListGroup,
  Card,
  Container,
  Form,
  Breadcrumb,
} from "react-bootstrap";

import { get } from "utils/DeApi";
import { UserContext } from "contexts/UserProvider";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import ProducerDelete from "../ProducerDelete/ProducerDelete";
import ProducerUpdate from "../ProducerUpdate/ProducerUpdate";

const ProducerDetails = () => {
  const subscribedPromises = useRef([]);

  const [topics, setTopics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingDashboard, setLoadingDashboard] = useState(false);
  const [error, setError] = useState(null);
  const [producer, setProducer] = useState();
  const [filterParameters, setfilterParameters] = useState({});
  const [topicsFilter, setTopicFilter] = useState({});
  const [dataset, setDataset] = useState([]);

  const params = useParams();
  const { producerId } = params;
  const navigate = useNavigate();
  const user = useContext(UserContext);

  useEffect(() => {
    const fetchProducer = () => {
      setError(null);
      setIsLoading(true);
      const producerPromise = get(`producers/${producerId}`);

      producerPromise.promise
        .then((response) => {
          setProducer(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(producerPromise);
    };

    fetchProducer();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [producerId]);

  useEffect(() => {
    const fetchProducerData = () => {
      if (!producer) {
        return;
      }

      setError(null);
      setLoadingDashboard(true);

      const producerDashboard = get(`producers/${producer.id}/dashboard`);

      producerDashboard.promise
        .then((response) => {
          let dataset = [];
          response.legislations.forEach((item) => {
            item.type = "Legislation";
            dataset.push(item);
          });

          response.reviews.forEach((item) => {
            item.type = "Summary";
            dataset.push(item);
          });

          const sortedDataset = dataset.sort(function (a, b) {
            return new Date(b.updatedAt) - new Date(a.updatedAt);
          });

          setDataset(sortedDataset);
          setLoadingDashboard(false);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setLoadingDashboard(false);
          }
        });
      subscribedPromises.current.push(producerDashboard);
    };

    fetchProducerData();
  }, [producer]);

  const filterLegislations = () => {
    let results = dataset;

    if (Object.keys(topicsFilter).length > 0) {
      for (let key of Object.keys(topicsFilter)) {
        if (topicsFilter[key] === false) {
          results = results.filter((item) => {
            return item.topic.id !== key;
          });
        }
      }
    }

    if (filterParameters?.startDate) {
      results = results.filter((item) => {
        return item.updatedAt >= filterParameters.startDate;
      });
    }

    if (filterParameters?.title) {
      results = results.filter((item) => {
        if (item.type === "Summary") {
          return (
            item.summary
              .toLowerCase()
              .includes(filterParameters.title.toLowerCase()) ||
            item.jurisdiction.title
              .toLowerCase()
              .includes(filterParameters.title.toLowerCase()) ||
            item.topic.name
              .toLowerCase()
              .includes(filterParameters.title.toLowerCase())
          );
        } else {
          return (
            item.name
              .toLowerCase()
              .includes(filterParameters.title.toLowerCase()) ||
            item.jurisdiction.title
              .toLowerCase()
              .includes(filterParameters.title.toLowerCase()) ||
            item.topic.name
              .toLowerCase()
              .includes(filterParameters.title.toLowerCase()) ||
            item.notes
              ?.toLowerCase()
              .includes(filterParameters.title.toLowerCase())
          );
        }
      });
    }

    if (filterParameters?.jurisdiction) {
      if (filterParameters.jurisdiction === "all") return results;
      results = results.filter((item) => {
        return item.jurisdiction.id === filterParameters.jurisdiction;
      });
    }

    return results;
  };

  useEffect(() => {
    const fetchTopics = () => {
      const topicsPromise = get("topics");
      topicsPromise.promise
        .then((response) => {
          let topics = response.data.map((item) => {
            item.isChecked = true;
            return item;
          });

          setTopics(topics);
        })
        .catch((error) => {
          if (!error.isCanceled) console.warn(error);
        });
      subscribedPromises.current.push(topicsPromise);
    };

    fetchTopics();
  }, []);

  const updateTopicFilter = (topic) => {
    topic.isChecked = !topic.isChecked;
    const topicId = topic.id;
    const topicChecked = topic.isChecked;
    setTopicFilter((prevState) => ({ ...prevState, [topicId]: topicChecked }));
  };

  const setFilterQuery = (event) => {
    event.preventDefault();
    const filterName = event.target.name;
    const value = event.target.value;

    setfilterParameters((prevState) => ({ ...prevState, [filterName]: value }));
  };

  const clearFilters = () => {
    setfilterParameters({});
    setTopicFilter({});
    setTopics((topics) => [
      ...topics.map((topic) => {
        topic.isChecked = true;
        return topic;
      }),
    ]);
  };

  const results = filterLegislations();

  if (!producer) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  return (
    <Container className="mt-3 mb-5">
      <Helmet>
        <title>{producer?.name}</title>
      </Helmet>
      {isLoading && <Loader />}
      {error && <ErrorHandler error={error} />}

      <Breadcrumb className="">
        <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item active>{producer?.name}</Breadcrumb.Item>
      </Breadcrumb>

      <div className="border-bottom pb-3">
        <div className="">
          <h2 className="font-semibold tracking-wide">{producer.name}</h2>
          <p className="text-gray-500">{producer.description}</p>
          <div className="mb-0 flex justify-between">
            <span className="space-x-3">
              {producer &&
                producer?.jurisdictions.map((jurisdiction) => (
                  <span
                    key={jurisdiction.id}
                    className="flex-none mt-2 bg-green-200 text-green-800 text-sm tracking-wide px-2 py-1 rounded-md"
                  >
                    <Link
                      to={`/producers/${producerId}/jurisdictions/${jurisdiction.id}`}
                    >
                      {jurisdiction.title}
                    </Link>
                  </span>
                ))}
            </span>
            <span className="-mt-2">
              {user && user.isAdmin ? (
                <div className="space-x-2 content-end flex-col mt-auto">
                  <ProducerUpdate
                    producer={producer}
                    onProducerUpdated={(prod) => setProducer(prod)}
                  />
                  <ProducerDelete
                    producer={producer}
                    onProducerDeleted={() => navigate("/")}
                  />
                </div>
              ) : (
                " "
              )}
            </span>
          </div>
        </div>
      </div>

      <Row className="py-3">
        <Col md={3}>
          <Form.Group>
            <Form.Label className="font-semibold">
              Select Jurisdiction
            </Form.Label>
            <select
              className="form-select"
              aria-label="Select jurisdcition"
              name="jurisdiction"
              onChange={(event) => setFilterQuery(event)}
              value={filterParameters?.jurisdiction || ""}
            >
              <option value="all">All jurisdictions...</option>
              {producer &&
                producer?.jurisdictions.map((jurisdiction) => (
                  <option key={jurisdiction.id} value={jurisdiction.id}>
                    {jurisdiction.title}
                  </option>
                ))}
            </select>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <Form.Label className="font-semibold">Date</Form.Label>
            <input
              className="form-control"
              type="date"
              name="startDate"
              onChange={(event) => setFilterQuery(event)}
              value={filterParameters?.startDate || ""}
            />
          </Form.Group>
        </Col>
        <Col md={6} className="flex justify-between">
          <Form.Group className="" controlId="exampleForm.ControlInput1">
            <Form.Label className="font-semibold mb-3">
              Topics filter
            </Form.Label>
            <br />
            {topics &&
              topics.map((topic) => (
                <Form.Check
                  key={topic.id}
                  inline
                  label={topic.name}
                  value={topic.id || ""}
                  name="topic"
                  type="checkbox"
                  checked={topic.isChecked}
                  onChange={() => updateTopicFilter(topic)}
                  id={`inline-${topic.id}`}
                />
              ))}
          </Form.Group>
          <div className="content-end flex-col mt-auto">
            <Button
              name="clearFilter"
              size="sm"
              variant="secondary"
              onClick={clearFilters}
            >
              Clear Filter
            </Button>
          </div>
        </Col>
      </Row>

      <Card>
        <Card.Body className="pt-0">
          <div className="flex justify-between border-bottom py-3 mb-2">
            <span className="tracking-wide text-xl pt-2">
              Latest Legislations & Summary Reviews
            </span>
            <div className="w-50">
              <input
                className="form-control"
                type="search"
                placeholder="Search by keyword..."
                aria-label="Search"
                name="title"
                onChange={(event) => setFilterQuery(event)}
                value={filterParameters?.title || ""}
              />
            </div>
          </div>
          {error && <ErrorHandler error={error} />}
          {loadingDashboard ? (
            <Loader />
          ) : results && results.length === 0 ? (
            <Alert variant="info" className="mb-0">
              Sorry, we could not find any legislations and summary reviews at
              this time.
            </Alert>
          ) : (
            <>
              <ListGroup variant="flush" className="">
                {results &&
                  results.map((item) => (
                    <ListGroup.Item key={item.id} className="px-0">
                      <div className="">
                        <p className="">{item?.notes || item?.summary}</p>
                        <div className="flex justify-between">
                          <span className="space-x-2">
                            <span className="flex-none mt-2 bg-green-200 text-xs tracking-wide px-2 py-1 rounded-md">
                              <Link
                                to={`/producers/${producerId}/jurisdictions/${item.jurisdiction.id}`}
                              >
                                {item.jurisdiction.title}
                              </Link>
                            </span>

                            <span className="flex-none mt-2 border text-gray-600 text-xs tracking-wide px-2 py-1 rounded-md">
                              {item.topic.name}
                            </span>

                            {item.type === "Legislation" ? (
                              <span className="flex-none mt-2 border text-gray-600 text-xs tracking-wide px-2 py-1 rounded-md">
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={item?.link}
                                  className=""
                                >
                                  {item.type}
                                </a>
                              </span>
                            ) : (
                              <span className="flex-none mt-2 border text-gray-600 text-xs tracking-wide px-2 py-1 rounded-md">
                                {item.type}
                              </span>
                            )}

                            <span className="flex-none mt-2 border text-gray-600 text-xs tracking-wide px-2 py-1 rounded-md">
                              Updated on{" "}
                              {new Date(item.updatedAt).toLocaleString([], {
                                dateStyle: "short",
                              })}
                              &nbsp; at &nbsp;
                              {new Date(item.updatedAt).toLocaleString([], {
                                timeStyle: "short",
                              })}
                            </span>
                          </span>

                          <span className="">
                            <Button
                              variant="secondary"
                              className="py-0"
                              size="sm"
                              as={Link}
                              to={`/producers/${producerId}/jurisdictions/${item.jurisdiction.id}/topics/${item.topic.id}?tab=${item.type}`}
                            >
                              Learn more →
                            </Button>
                          </span>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ProducerDetails;
