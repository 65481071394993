import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Footer = () => {
  return (
    <div className="Footer bg-primary text-light text-opacity-75 mt-10">
      <Container fluid>
        <Row>
          <Col xs={12} className="my-2">
            <small className="float-end">
              &copy; {new Date().getFullYear()}
            </small>
            ERM
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
