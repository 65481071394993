import NoMatch from "components/App/NoMatch/NoMatch";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Nav } from "react-bootstrap";
import { Link, Route, Routes, useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { get } from "utils/DeApi";
import ProducerJurisdictionOverview from "../ProducerJurisdictionOverview/ProducerJurisdictionOverview";
import ProducerJurisdictionTopic from "../ProducerJurisdictionTopic/ProducerJurisdictionTopic";

const ProducerJurisdiction = () => {
  const subscribedPromises = useRef([]);

  const [jurisdiction, setJurisdiction] = useState();
  const [topics, setTopics] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [producer, setProducer] = useState({});

  const params = useParams();
  const { producerId, jurisdictionId } = params;
  const location = useLocation();

  useEffect(() => {
    const fetchProducer = () => {
      setError(null);
      setIsLoading(true);
      const producerPromise = get(`producers/${producerId}`);

      producerPromise.promise
        .then((response) => {
          setProducer(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(producerPromise);
    };

    fetchProducer();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [producerId]);

  useEffect(() => {
    const fetchJurisdiction = () => {
      setError(null);
      setIsLoading(true);
      const jurisdictionPromise = get(`jurisdictions/${jurisdictionId}`);
      jurisdictionPromise.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);
          setJurisdiction(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(jurisdictionPromise);
    };

    fetchJurisdiction();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [jurisdictionId]);

  useEffect(() => {
    const fetchTopics = () => {
      const topicsPromise = get("topics");
      topicsPromise.promise
        .then((response) => {
          setTopics(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) console.warn(error);
        });
      subscribedPromises.current.push(topicsPromise);
    };

    fetchTopics();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!jurisdiction) return <span />;
  if (!producer) return <span />;

  return (
    <Row>
      <Helmet>
        <title>{jurisdiction?.title}</title>
      </Helmet>
      <Col xs={12} md={3} className="py-3 scroller">
        <h4 className="">
          <Link
            className="text-decoration-none"
            to={`/producers/${producerId}`}
          >
            {producer.name}
          </Link>
        </h4>
        <div className="">
          <h1 className="mb-0">{jurisdiction.title}</h1>
          <p className="flex space-x-3">
            <span className="flex-none mt-2 bg-green-200 text-green-700 text-xs tracking-wide font-semibold px-2 py-1 rounded-md">
              {jurisdiction.category}
            </span>

            <span className="flex-none mt-2 bg-green-200 text-green-700 text-xs tracking-wide font-semibold px-2 py-1 rounded-md">
              {jurisdiction.name}
            </span>
          </p>
          <h4>
            <Link
              className="text-decoration-none tracking-wide mb-3"
              to={`/producers/${producerId}/jurisdictions/${jurisdictionId}`}
            >
              Overview
            </Link>
          </h4>
        </div>
        <hr />
        {topics && (
          <>
            <h3 className="mb-3 text-gray-500">Topics</h3>
            <Nav
              className="flex-column"
              variant="pills"
              activeKey={location?.pathname}
            >
              {topics &&
                topics.map((topic) => (
                  <Nav.Item key={topic.id}>
                    <Nav.Link
                      as={Link}
                      className="tracking-wide"
                      to={`/producers/${producerId}/jurisdictions/${jurisdictionId}/topics/${topic.id}`}
                      href={`/producers/${producerId}/jurisdictions/${jurisdictionId}/topics/${topic.id}`}
                    >
                      {topic.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
            </Nav>
          </>
        )}
      </Col>
      <Col xs={12} md={9}>
        {topics && (
          <Routes>
            <Route
              path="/"
              element={
                <ProducerJurisdictionOverview
                  producer={producer}
                  topics={topics}
                  jurisdiction={jurisdiction}
                />
              }
            />
            <Route
              path="/topics/:topicId"
              element={
                <ProducerJurisdictionTopic
                  producer={producer}
                  topics={topics}
                  jurisdiction={jurisdiction}
                  key={location.pathname}
                />
              }
            />
            <Route element={NoMatch} />
          </Routes>
        )}
      </Col>
    </Row>
  );
};

export default ProducerJurisdiction;
