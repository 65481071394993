import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { get } from "utils/DeApi";

import Requirement from "../Requirement/Requirement";

const SectionAssessment = ({ section, jurisdiction }) => {
  const subscribedPromises = useRef([]);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const fetchQuestions = () => {
      const questionsPromise = get(`sections/${section.id}/topic-questions`);
      questionsPromise.promise
        .then((response) => {
          setQuestions(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) console.warn(error);
        });
      subscribedPromises.current.push(questionsPromise);
    };
    fetchQuestions();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [section]);

  return (
    <>
      {questions.map((question) => (
        <tr key={question.id}>
          <td className="w-1/2 border-end">{question.prompt}</td>
          <Requirement question={question} jurisdiction={jurisdiction} />
        </tr>
      ))}
    </>
  );
};
SectionAssessment.propTypes = {
  section: PropTypes.object.isRequired,
};

export default SectionAssessment;
