import React, { useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import "./App.css";

import Login from "./Login/Login";
import Logout from "./Logout/Logout";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import NoMatch from "./NoMatch/NoMatch";
import Unauthorized from "./Unauthorized/Unauthorized";

import { UserContext, UserProvider } from "contexts/UserProvider";

import Dashboard from "components/Dashboard/Dashboard";
import Content from "components/Content/Content";
import Topic from "components/Topic/Topic";
import Regulation from "components/Regulation/Regulation";
import Producer from "components/Producer/Producer";
import Jurisdiction from "components/Jurisdiction/Jurisdiction";

/**
 * Handles application level routing.
 * It sets a middleware for private and public routes.
 */
const App = () => {
  return (
    <div className="Main bg-slate-100">
      <ScrollToTop />
      <UserProvider>
        <Router>
          <Routes>
            <Route path="" element={<PrivateRoute />}>
              <Route index path="/" element={<Dashboard />} />
              <Route index path="producers/*" element={<Producer />} />

              <Route
                index
                path="regulations/*"
                element={
                  <AdminRoute>
                    <Regulation />
                  </AdminRoute>
                }
              />
              <Route
                index
                path="jurisdictions/*"
                element={
                  <AdminRoute>
                    <Jurisdiction />
                  </AdminRoute>
                }
              />
              <Route
                index
                path="topics/*"
                element={
                  <AdminRoute>
                    <Topic />
                  </AdminRoute>
                }
              />

              <Route path="contents/*" element={<Content />} />
              <Route path="logout" element={<Logout />} />
            </Route>

            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </Router>
      </UserProvider>
    </div>
  );
};

/** Middleware for private routes. */
const PrivateRoute = () => {
  const location = useLocation();
  const user = useContext(UserContext);
  return user ? (
    <Outlet />
  ) : (
    <Navigate to="login" state={{ from: location }} replace />
  );
};

const AdminRoute = ({ children }) => {
  const user = useContext(UserContext);
  return user.isAdmin ? children : <Unauthorized />;
};

/** Scroll To Top on Navigation */
const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
};

export default App;
