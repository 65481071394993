import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Navbar,
  Badge,
  Alert,
  ListGroup,
  Button,
} from "react-bootstrap";
import "./Notifications.scss";

import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { Link } from "react-router-dom";
import { get } from "utils/DeApi";

const Notifications = ({ producerId }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [notifications, setNotifications] = useState([]);
  const subscribedPromises = useRef([]);

  useEffect(() => {
    const fetchNotifications = () => {
      const notificationsPromise = get(
        `/producers/${producerId}/notifications`
      );
      setIsLoading(true);
      notificationsPromise.promise
        .then((response) => {
          const { legislations = [], reviews = [] } = response;
          let dataset = [
            ...legislations.map((legislation) => ({
              ...legislation,
              type: "Legislation",
            })),
            ...reviews.map((review) => ({
              ...review,
              type: "Summary",
            })),
          ]
            .sort(function (a, b) {
              return new Date(b.updatedAt) - new Date(a.updatedAt);
            })
            .slice(0, 5);

          setNotifications(dataset);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
      subscribedPromises.current.push(notificationsPromise);
    };

    fetchNotifications();

    const promises = subscribedPromises.current;
    return () => {
      setNotifications([]);
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  function handleCloseModal() {
    setShow(false);
    setError("");
  }

  function handleShowModal() {
    setShow(true);
  }

  return (
    <>
      <Navbar.Text className="Feedback me-3" onClick={handleShowModal}>
        <span className="material-icons-outlined md-18">notifications</span>
        What's New{" "}
        <sup>
          <Badge bg="primary" size="sm" className="float-end ms-1">
            {(notifications && notifications.length) || 0}
          </Badge>
        </sup>
      </Navbar.Text>

      <Modal show={show} size="lg" onHide={handleCloseModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className="grow flex justify-between">
            <span className="text-lg mt-2">
              Recent Regulatory Changes & Updates
            </span>

            {/* <span className="flex justify-end space-x-3 w-1/2">
              <select
                className="form-select w-1/3 inline-block ml-auto"
                name="filterNotifications"
                value="all"
                onChange={handleFilterChange}
              >
                <option value="all">All</option>
                <option value="unread">Unread</option>
                <option value="read">Read</option>
              </select>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="clear all">Mark all as Read</Tooltip>}
              >
                <Button variant="outline-success" size="sm" className="">
                  <span className="material-icons-outlined">done_all</span>
                </Button>
              </OverlayTrigger>
              <span className="mr-3"></span>
            </span> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <Loader />
          ) : notifications && notifications.length > 0 ? (
            <ListGroup variant="flush">
              {notifications.map((item) => {
                let notice = item?.notes || item?.summary;
                return (
                  <ListGroup.Item key={item.id}>
                    <Button
                      as={Link}
                      to="#"
                      variant="link"
                      size="sm"
                      className="float-end"
                    >
                      View{" "}
                      <span className="material-icons-outlined">
                        swipe_right_alt
                      </span>
                    </Button>
                    <span className="text-sm">
                      {notice && notice.length > 250
                        ? notice.substring(0, 250) + "..."
                        : notice}
                    </span>
                    <br />
                    <span className="space-x-2">
                      <span className="flex-none border text-gray-600 text-xs tracking-wide px-2 py-1 rounded-md">
                        {item.type}
                      </span>
                      <span className="flex-none border text-gray-600 text-xs tracking-wide px-2 py-1 rounded-md">
                        {item.topic.name}
                      </span>
                      <span className="flex-none border text-gray-600 text-xs tracking-wide px-2 py-1 rounded-md">
                        {item.jurisdiction.title}
                      </span>
                      <span className="flex-none border text-gray-600 text-xs tracking-wide px-2 py-1 rounded-md">
                        Updated on{" "}
                        {new Date(item.updatedAt).toLocaleString([], {
                          dateStyle: "short",
                        })}
                        &nbsp; at &nbsp;
                        {new Date(item.updatedAt).toLocaleString([], {
                          timeStyle: "short",
                        })}
                      </span>
                    </span>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          ) : (
            <Alert variant="primary">
              No new notifications for you at this time!
            </Alert>
          )}

          {error && <ErrorHandler error={error} />}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            variant="outline-primary"
            as={Link}
            to="#"
            className="flex-none text-green-600 font-semibold py-1 px-2 rounded-md uppercase text-xs tracking-wide"
          >
            See all
            <span className="material-icons-outlined">arrow_right_alt</span>
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Notifications;
