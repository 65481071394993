import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { ListGroup, Col, Row, Card } from "react-bootstrap";
import { get, put } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import AddSection from "./AddSection/AddSection";
import UpdateTopic from "./UpdateTopic/UpdateTopic";

import DraggableSection from "./DraggableSection/DraggableSection";
import {
  DndContext,
  closestCenter,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

const TopicDetails = ({
  topics,
  onSectionsOrdered,
  onSectionCreated,
  onSectionDeleted,
  onSectionUpdated,
}) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [sections, setSections] = useState();
  const [activeSection, setActiveSection] = useState(null);
  const [topic, setTopic] = useState();

  const sensors = useSensors(
    useSensor(MyPointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const params = useParams();
  const topicId = params.topicId;
  const activeTopic = topics.find((item) => item.id === topicId);

  useEffect(() => {
    setTopic(activeTopic);
  }, [activeTopic]);

  useEffect(() => {
    const fetchSections = () => {
      setError(null);
      setIsLoading(true);
      const sectionsPromise = get(`topics/${topicId}/sections`);
      sectionsPromise.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);
          setSections(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(sectionsPromise);
    };

    fetchSections();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [topicId]);

  const handleSectionCreated = (section) => {
    setSections((sections) => [...sections, section]);
    onSectionCreated && onSectionCreated(section);
  };

  const handleSectionDeleted = (section) => {
    setSections((sections) => [
      ...sections.filter((sec) => sec.id !== section.id),
    ]);
    onSectionDeleted && onSectionDeleted(section);
  };

  const handleSectionUpdated = (section) => {
    setSections((sections) => [
      ...sections.map((sec) => (sec.id === section.id ? section : sec)),
    ]);
    onSectionUpdated && onSectionUpdated(section);
  };

  const handleDragStarted = (event) => {
    const { active } = event;
    const section = sections.find((item) => item.id === active.id);

    setActiveSection(section);
  };

  const handleDragEnded = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setSections((sections) => {
        const oldIndex = sections.findIndex((item) => {
          return item.id === active.id;
        });
        const newIndex = sections.findIndex((item) => {
          return item.id === over.id;
        });
        onSectionsOrdered && onSectionsOrdered(oldIndex, newIndex);
        return arrayMove(sections, oldIndex, newIndex);
      });

      const index = sections.findIndex((item) => {
        return item.id === over.id;
      });
      updateSectionsOrder(index, active.id);
    }

    setActiveSection(null);
  };

  const updateSectionsOrder = (index, sectionId) => {
    const updatePromise = put(`sections/${sectionId}/order`, {
      current: index + 1,
    });

    updatePromise.promise
      .then((response) => {})
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
        }
      });
  };

  const handleTopicUpdated = (topic) => {
    setTopic(topic);
  };

  if (!topic) return <span />;

  return (
    <Row>
      <Col xs={12} className="py-4">
        <div className="flex justify-between">
          <h1>{topic.name}</h1>
          <div>
            <UpdateTopic topic={topic} onTopicUpdated={handleTopicUpdated} />
          </div>
        </div>

        <p>{topic.description}</p>
        <p>
          <span className="flex-none mt-2 border text-gray-600 text-xs tracking-wide px-2 py-1 rounded-md">
            Updated on{" "}
            {new Date(topic.updatedAt).toLocaleString([], {
              dateStyle: "short",
            })}
            &nbsp; at &nbsp;
            {new Date(topic.updatedAt).toLocaleString([], {
              timeStyle: "short",
            })}
          </span>
        </p>
        <hr />

        <Card>
          <Card.Body>
            <div className="d-flex border-bottom justify-content-between pb-2">
              <h3>Sections</h3>
              <AddSection
                topic={topic}
                onSectionCreated={handleSectionCreated}
              />
            </div>

            {error && <ErrorHandler error={error} />}

            {isLoading ? (
              <Loader />
            ) : (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStarted}
                onDragEnd={handleDragEnded}
              >
                <ListGroup variant="flush" className="">
                  {sections && (
                    <>
                      <SortableContext
                        items={sections}
                        strategy={verticalListSortingStrategy}
                      >
                        {sections &&
                          sections.map((section) => (
                            <DraggableSection
                              key={section.id}
                              id={section.id}
                              section={section}
                              onSectionUpdated={handleSectionUpdated}
                              onSectionDeleted={handleSectionDeleted}
                            />
                          ))}
                      </SortableContext>
                      <DragOverlay>
                        {activeSection ? (
                          <DraggableSection
                            className="bg-green-50"
                            id={activeSection.id}
                            section={activeSection}
                            onSectionUpdated={handleSectionUpdated}
                            onSectionDeleted={handleSectionDeleted}
                          />
                        ) : null}
                      </DragOverlay>
                    </>
                  )}
                </ListGroup>
              </DndContext>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

TopicDetails.propTypes = {
  topics: PropTypes.array.isRequired,
};

export default TopicDetails;

class MyPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: "onPointerDown",
      handler: ({ nativeEvent: event }) => {
        if (
          !event.isPrimary ||
          event.button !== 0 ||
          isInteractiveElement(event.target)
        ) {
          return false;
        }

        return true;
      },
    },
  ];
}

function isInteractiveElement(element) {
  const interactiveElements = [
    "button",
    "input",
    "textarea",
    "select",
    "option",
  ];

  if (interactiveElements.includes(element.tagName.toLowerCase())) {
    return true;
  }

  return false;
}
