import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

/**
 * 404: No match route
 */
const NoMatch = ({ location }) => (
  <Col xs={12}>
    <h1 className="text-center mt-5 pt-5">404 - Page not found</h1>
    <p className="text-center">
      You might want to go to the <Link to="/">/Home page</Link>
    </p>
  </Col>
);

export default NoMatch;
