import React from "react";
import { useState, useEffect, useRef, Fragment } from "react";
import { get } from "utils/DeApi";

const Requirement = ({ question, jurisdiction }) => {
  const subscribedPromises = useRef([]);

  const [requirements, setRequirements] = useState([]);

  useEffect(() => {
    const fetchRequirements = () => {
      const requirementsPromise = get(
        `topic-questions/${question.id}/regulatory-requirements`,
        { params: { jurisdictionId: jurisdiction.id } }
      );
      requirementsPromise.promise
        .then((response) => {
          setRequirements(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) console.warn(error);
        });
      subscribedPromises.current.push(requirementsPromise);
    };

    fetchRequirements();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [question, jurisdiction]);

  return (
    <Fragment>
      {requirements?.length ? (
        requirements.map((requirement) => (
          <Fragment key={requirement.id}>
            <td key={requirement.id} className="border-start">
              <p>
                {requirement.response}
                <span className="float-right flex-none mt-2 border text-gray-600 text-xs tracking-wide font-semibold px-2 py-1 rounded-md">
                  Updated on{" "}
                  {new Date(requirement.updatedAt).toLocaleString([], {
                    dateStyle: "short",
                  })}
                  &nbsp; at &nbsp;
                  {new Date(requirement.updatedAt).toLocaleString([], {
                    timeStyle: "short",
                  })}
                </span>
              </p>
            </td>
          </Fragment>
        ))
      ) : (
        <Fragment>
          <td className="border-start"></td>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Requirement;
