import React, { useEffect, useRef, useState } from "react";

import Header from "components/App/Header/Header";
import { Card, Col, Container, Row } from "react-bootstrap";
import Footer from "components/App/Footer/Footer";
import ProducerList from "components/Producer/ProducerList/ProducerList";
import { get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const Dashboard = () => {
  const [topics, setTopics] = useState([]);
  const [jurisdictions, setJurisdictions] = useState([]);
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchTopics = () => {
      const fetchTopicsPromise = get("topics");
      setIsLoading(true);
      setError(null);

      fetchTopicsPromise.promise
        .then((response) => {
          setTopics(response.data);
          fetchJurisdictions();
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(fetchTopicsPromise);
    };

    fetchTopics();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  const fetchJurisdictions = () => {
    const fetchJurisdictionsPromise = get("jurisdictions");
    setIsLoading(true);
    setError(null);
    fetchJurisdictionsPromise.promise
      .then((response) => {
        setJurisdictions(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(fetchJurisdictionsPromise);
  };

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  return (
    <>
      <Header title="Dashboard" />
      <Container fluid className="py-5 MainContent">
        <Row className="justify-content-center">
          <Col xs={12} md={5} className="my-3">
            <h1>Hi, Welcome back!</h1>
            <h6>Your EPRComplete dashboard.</h6>
          </Col>
          <Col xs={12} md={4} className="my-3 text-end">
            <div className="d-inline-flex">
              <div className="px-3 border-end">
                <span className="fs-1 me-2">{topics.length || 0}</span>
                <small>EPR Topics</small>
              </div>
              <div className="px-3">
                <span className="fs-1 me-2">{jurisdictions.length || 0}</span>
                <small>Jurisdictions Covered</small>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} md={9} className="my-3">
            <Card className="">
              <Card.Body>
                <ProducerList />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Dashboard;
