import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { put } from "utils/DeApi";

const UpdateTopic = ({ topic, onTopicUpdated }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    name: yup.string().min(2, "Too Short!").max(500, "Too Long!").required(),
    description: yup.string().max(1000, "Too Long!").nullable(),
    info: yup
      .string()
      .nullable()
      .min(5, "That id is too short!")
      .max(35, "Too Long"),
  });

  const updateTopic = (values) => {
    const updatePromise = put(`topics/${topic.id}`, values);
    setIsLoading(true);
    setError(null);

    updatePromise.promise
      .then((response) => {
        setIsLoading(false);
        onTopicUpdated && onTopicUpdated(response.data);
        handleClose();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      <Button variant="secondary" size="sm" onClick={handleShow}>
        Update
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update Topic</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updateTopic(values)}
          initialValues={{
            name: topic.name,
            description: topic.description,
            info: topic.info,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form onKeyDown={(e) => e.stopPropagation()}>
              <Modal.Body>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.name}
                    isInvalid={errors.name && touched.name}
                    disabled={true}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    as="textarea"
                    rows={5}
                    isValid={values.description}
                    isInvalid={errors.description && touched.description}
                  />
                  <Form.Text className="text-muted">
                    {1000 - values.description.length} characters left
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="info">
                  <Form.Label>Info</Form.Label>
                  <Form.Control
                    name="info"
                    value={values.info}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.info}
                    isInvalid={errors.info && touched.info}
                    placeholder="Link"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.info}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>
              <Modal.Footer>
                <Button size="sm" variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  size="sm"
                  variant="primary"
                  disabled={!isValid}
                >
                  Update Topic
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default UpdateTopic;
