import React, { useState, useEffect, useRef } from "react";
import "./JurisdictionDetails.scss";
import {
  Link,
  Route,
  Routes,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { Col, Row, Dropdown, Nav, Collapse } from "react-bootstrap";
import { Helmet } from "react-helmet";

import { get } from "utils/DeApi";
import NoMatch from "components/App/NoMatch/NoMatch";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import JurisdictionUpdate from "../JurisdictionUpdate/JurisdictionUpdate";
import JurisdictionDelete from "../JurisdictionDelete/JurisdictionDelete";

// import TopicNavigation from "./TopicNavigation/TopicNavigation";

import JurisdictionOverview from "./JurisdictionOverview/JurisdictionOverview";
import JurisdictionTopicReviews from "../JurisdictionTopicReviews/JurisdictionTopicReviews";
import JurisdictionLegislations from "../JurisdictionLegislations/JurisdictionLegislations";
import JurisdictionAssessment from "../JurisdictionAssessment/JurisdictionAssessment";

const JurisdictionDetails = () => {
  const subscribedPromises = useRef([]);

  const [topics, setTopics] = useState();
  const [jurisdiction, setJurisdiction] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [activeTopicId, setActiveTopicId] = useState();

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const jurisdictionId = params.id;

  useEffect(() => {
    const fetchJurisdiction = () => {
      setError(null);
      setIsLoading(true);
      const jurisdictionPromise = get(`jurisdictions/${jurisdictionId}`);
      jurisdictionPromise.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);
          setJurisdiction(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(jurisdictionPromise);
    };

    fetchJurisdiction();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [jurisdictionId]);

  useEffect(() => {
    const fetchTopics = () => {
      const topicsPromise = get("topics");
      topicsPromise.promise
        .then((response) => {
          setTopics(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) console.warn(error);
        });
      subscribedPromises.current.push(topicsPromise);
    };

    fetchTopics();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  useEffect(() => {
    const topicId = getTopicIdFromUrl(params);
    setActiveTopicId(topicId);
  }, [params]);

  const getTopicIdFromUrl = (params) => {
    let str = params["*"];
    if (str === "") return null;

    if (str.includes("/")) return str.split("/")[1];

    return str;
  };

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  if (!jurisdiction) return <span />;

  return (
    <Row>
      <Helmet>
        <title>{jurisdiction.title}</title>
      </Helmet>
      <Col xs={12} md={3} className="scroller">
        <div className="py-4">
          <h4 className="border px-2 py-2 border-gray-600 shadow-sm bg-white rounded-full">
            <Link className="text-decoration-none mt-5" to={`/regulations`}>
              <span className="material-icons-outlined">
                keyboard_backspace
              </span>
              All Regulations
            </Link>
          </h4>
          <hr />
          <div className="">
            <h1>{jurisdiction.title}</h1>
            <p>
              <span
                key={jurisdiction.id}
                className="flex-none mt-2 bg-green-200 text-green-700 text-xs tracking-wide font-semibold px-2 py-1 rounded-md"
              >
                <span className="material-icons md-18 text-muted">place</span>{" "}
                {jurisdiction.name}
              </span>
            </p>
            <p>
              <span
                key={jurisdiction.id}
                className="flex-none mt-2 bg-green-200 text-green-700 text-xs tracking-wide font-semibold px-2 py-1 rounded-md"
              >
                <span className="material-icons md-18 text-muted">place</span>{" "}
                {jurisdiction.category}
              </span>
            </p>

            <p>
              <span className="flex-none mt-2 border text-gray-600 text-xs tracking-wide font-semibold px-2 py-1 rounded-md">
                <span className="material-icons md-18 text-muted">
                  schedule
                </span>{" "}
                Updated on{" "}
                {new Date(jurisdiction.updatedAt).toLocaleString([], {
                  dateStyle: "short",
                })}
                &nbsp; at &nbsp;
                {new Date(jurisdiction.updatedAt).toLocaleString([], {
                  timeStyle: "short",
                })}
              </span>
            </p>
          </div>

          <h4>
            <Dropdown className="float-end">
              <Dropdown.Toggle className="btn-sm bg-primary bg-opacity-10 rounded-3 text-dark border-0">
                <span className="material-icons-outlined md-18">settings</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <JurisdictionUpdate
                    jurisdiction={jurisdiction}
                    onJurisdictionUpdated={(updated) =>
                      setJurisdiction(updated)
                    }
                  />
                </Dropdown.Item>
                <Dropdown.Item>
                  <JurisdictionDelete
                    jurisdiction={jurisdiction}
                    onJurisdictionDeleted={() => navigate("/regulations")}
                  />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Link
              className="text-decoration-none"
              to={`/jurisdictions/${jurisdictionId}`}
            >
              Overview
            </Link>
          </h4>
          <hr />

          <Nav
            className="flex-column space-y-2"
            variant="pills"
            activeKey={location?.pathname}
          >
            {topics &&
              topics.map((topic) => (
                <Nav.Item key={topic.id}>
                  <Nav.Link
                    as={Link}
                    to={`/jurisdictions/${jurisdiction.id}/topics/${topic.id}/summary`}
                    href={`/jurisdictions/${jurisdiction.id}/topics/${topic.id}`}
                    data-bs-toggle="collapse"
                    aria-expanded={activeTopicId === topic.id ? true : false}
                    aria-controls={`${topic.name}-collapse`}
                    className="btn-toggle font-semibold text-lg before:mr-2"
                  >
                    {topic.name}
                  </Nav.Link>
                  <Collapse in={activeTopicId === topic.id}>
                    <div id={`${topic.name}-collapse`}>
                      <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                        <li>
                          <Nav.Link
                            as={Link}
                            to={`/jurisdictions/${jurisdiction.id}/topics/${topic.id}/summary`}
                            href={`/jurisdictions/${jurisdiction.id}/topics/${topic.id}/summary`}
                          >
                            {`Summary`}
                          </Nav.Link>
                        </li>
                        <li>
                          <Nav.Link
                            as={Link}
                            to={`/jurisdictions/${jurisdiction.id}/topics/${topic.id}/legislations`}
                            href={`/jurisdictions/${jurisdiction.id}/topics/${topic.id}/legislations`}
                          >
                            {`Legal Assessment`}
                          </Nav.Link>
                        </li>
                        <li>
                          <Nav.Link
                            as={Link}
                            to={`/jurisdictions/${jurisdiction.id}/topics/${topic.id}/assessment`}
                            href={`/jurisdictions/${jurisdiction.id}/topics/${topic.id}/assessment`}
                          >
                            {`Topic Assessment`}
                          </Nav.Link>
                        </li>
                      </ul>
                    </div>
                  </Collapse>
                </Nav.Item>
              ))}
          </Nav>
        </div>
      </Col>
      <Col xs={12} md={9}>
        {topics && (
          <Routes>
            <Route
              path="/"
              element={
                <JurisdictionOverview
                  topics={topics}
                  jurisdiction={jurisdiction}
                />
              }
            />
            <Route
              path="/topics/:topicId/summary"
              element={
                <JurisdictionTopicReviews
                  topics={topics}
                  jurisdiction={jurisdiction}
                />
              }
            />
            <Route
              path="/topics/:topicId/legislations"
              element={
                <JurisdictionLegislations
                  topics={topics}
                  jurisdiction={jurisdiction}
                />
              }
            />

            <Route
              path="/topics/:topicId/assessment"
              element={
                <JurisdictionAssessment
                  topics={topics}
                  jurisdiction={jurisdiction}
                />
              }
            />

            {/* <Route
              path="/topics/:topicId/questions/:questionId"
              element={<Question />}
            /> */}
            <Route element={NoMatch} />
          </Routes>
        )}
      </Col>
    </Row>
  );
};

JurisdictionDetails.propTypes = {};

export default JurisdictionDetails;
