import React, { useState, useEffect, useContext } from "react";

import "./Logout.css";

import { logout } from "utils/BeeApi";
import { logout as logoutDE } from "utils/DeApi";

import Loader from "components/Loader/Loader";
import { UserDispatchContext } from "contexts/UserProvider";

const Logout = () => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const setUser = useContext(UserDispatchContext);

  useEffect(() => {
    setIsLoggingOut(true);
    logout()
      .then(() => {
        return logoutDE();
      })
      .then(() => {
        setIsLoggingOut(false);
        setUser(null);
      })
      .catch((error) => {
        console.warn(error);
      });
  }, [setUser]);

  if (isLoggingOut) return <Loader />;
  return <span />;
};

export default Logout;
