import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik, Field } from "formik";

import { put } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const JurisdictionUpdate = ({ jurisdiction, onJurisdictionUpdated }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    title: yup.string().min(2, "Too Short!").max(100, "Too Long!").required(),
    name: yup.string().min(2, "Too Short!").max(100, "Too Long!").required(),
    category: yup.string(),
    ermCoverage: yup.boolean(),
  });

  const updateJurisdiction = ({ name, title, category, ermCoverage }) => {
    console.log(name);
    setError(null);
    setIsLoading(true);

    const jurisdictionPromise = put(`/jurisdictions/${jurisdiction.id}`, {
      name: name,
      title: title,
      category: category,
      ermCoverage: ermCoverage,
    });

    jurisdictionPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onJurisdictionUpdated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow} size="sm">
        Update Jurisdiction
      </Button>

      <Modal show={show} backdrop={"static"} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Update Jurisdiction <i>{jurisdiction.title}</i>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updateJurisdiction(values)}
          initialValues={{
            name: jurisdiction.name,
            title: jurisdiction.title,
            category: jurisdiction.category,
            ermCoverage: jurisdiction.ermCoverage,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form onKeyDown={(e) => e.stopPropagation()}>
              <Modal.Body>
                <Form.Group controlId="jurisdictionTitle" className="my-3">
                  <Form.Label>Jurisdiction Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.title}
                    isInvalid={errors.title && touched.title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="jurisdictionName" className="my-3">
                  <Form.Label>Jurisdiction Name (code)</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.name}
                    isInvalid={errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.includes("must not be one of")
                      ? "Jurisdiction already exists."
                      : errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="lens">
                  <Form.Label>Select Category</Form.Label>
                  <Field
                    as={Form.Select}
                    name="category"
                    value={values.category}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.category}
                  >
                    <option key={"global"} value={"global"}>
                      Global
                    </option>
                    <option key={"country"} value={"country"}>
                      Country
                    </option>
                    <option key={"state"} value={"state"}>
                      State
                    </option>
                    <option key={"province"} value={"province"}>
                      Province
                    </option>
                    <option key={"region"} value={"region"}>
                      Region
                    </option>
                  </Field>
                </Form.Group>
                {/* <Form.Group className="d-inline-flex mt-3">
                  <Form.Label className="me-2">ERM Coverage</Form.Label>

                  <Field
                    as={Form.Switch}
                    onChange={handleChange}
                    checked={values.ermCoverage}
                    value={values.ermCoverage}
                    type="switch"
                    name="ermCoverage"
                  />
                </Form.Group> */}

                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button onClick={handleSubmit} size="sm" disabled={!isValid}>
                  Update Jurisdiction
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

JurisdictionUpdate.propTypes = {
  jurisdiction: PropTypes.object.isRequired,
  onJurisdictionUpdated: PropTypes.func.isRequired,
};

export default JurisdictionUpdate;
