import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import { Modal, Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";

import { post } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const ReviewCreate = ({ jurisdiction, topic, onReviewCreated }) => {
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    summary: yup
      .string()
      .min(2, "Too Short!")
      .max(10000, "Maximum 10000 characters!")
      .required(),
  });

  const createReview = ({ summary }) => {
    setError(null);
    setIsLoading(true);

    const reviewPromise = post(`jurisdictions/${jurisdiction.id}/reviews`, {
      summary: summary,
      topicId: topic.id,
    });

    reviewPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onReviewCreated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(reviewPromise);
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow} size="sm">
        New Review
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>New Review</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => createReview(values)}
          initialValues={{
            summary: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="summary" className="mt-3 mb-3">
                  <Form.Control
                    as="textarea"
                    name="summary"
                    placeholder="Your may enter your regulations review, summary or comments here."
                    value={values.summary}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={7}
                    isValid={values.summary}
                    isInvalid={errors.summary && touched.summary}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.summary}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" size="sm" disabled={!isValid}>
                  Add Review
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

ReviewCreate.propTypes = {
  jurisdiction: PropTypes.object.isRequired,
  topic: PropTypes.object.isRequired,
  onReviewCreated: PropTypes.func.isRequired,
};

export default ReviewCreate;
