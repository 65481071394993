import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as yup from "yup";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { get, post } from "utils/DeApi";

const AddSection = ({ topic, onSectionCreated }) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const [topics, setTopics] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    fetchAllTopics();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  const fetchAllTopics = () => {
    const getPromise = get("topics");

    getPromise.promise
      .then((response) => {
        setTopics(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
        }
      });
    subscribedPromises.current.push(getPromise);
  };

  const schema = yup.object().shape({
    name: yup.string().min(2, "Too Short!").max(500, "Too Long!").required(),
    topicId: yup.string().oneOf(
      topics.map((tp) => tp.id),
      "That topic does not exists"
    ),
    description: yup.string().max(1000, "Too Long!").nullable(),
    info: yup.string().nullable().min(5, "That id is too short!"),
  });

  const createSection = (values) => {
    const createPromise = post(`topics/${topic.id}/sections`, values);
    setIsLoading(true);
    setError(null);

    createPromise.promise
      .then((response) => {
        setIsLoading(false);
        onSectionCreated(response.data);
        handleClose();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      <Button variant="primary" size="sm" onClick={handleShow}>
        Add New Section
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Create Section</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => createSection(values)}
          initialValues={{
            name: "",
            topicId: topic.id,
            description: "",
            info: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form onKeyDown={(e) => e.stopPropagation()}>
              <Modal.Body>
                <Form.Group className="mb-3" controlId="lens">
                  <Form.Label>Select Topic</Form.Label>
                  <Field
                    as={Form.Select}
                    name="topicId"
                    value={values.topicId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.topicId}
                    disabled={true}
                  >
                    {topics.map((tp) => {
                      return (
                        <option key={tp.id} value={tp.id}>
                          {tp.name}
                        </option>
                      );
                    })}
                  </Field>
                  <Form.Control.Feedback type="invalid">
                    {errors.topicId}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.name}
                    isInvalid={errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    as="textarea"
                    rows={3}
                    isValid={values.description}
                    isInvalid={errors.description && touched.description}
                  />
                  <Form.Text className="text-muted">
                    {1000 - values.description.length} characters left
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="info">
                  <Form.Label>Info</Form.Label>
                  <Form.Control
                    name="info"
                    value={values.info}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.info}
                    isInvalid={errors.info && touched.info}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.info}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>
              <Modal.Footer>
                <Button size="sm" variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  size="sm"
                  variant="primary"
                  disabled={!isValid}
                >
                  Create Section
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddSection;
