import React from "react";
import { Container } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import Footer from "components/App/Footer/Footer";
import Header from "../App/Header/Header";
import NoMatch from "../App/NoMatch/NoMatch";
import JurisdictionDetails from "./JurisdictionDetails/JurisdictionDetails";

const Jurisdiction = () => {
  return (
    <>
      <Header title="Jurisdictions" />
      <Container fluid className="MainContent mb-5">
        <Routes>
          <Route path=":id/*" element={<JurisdictionDetails />} />
          <Route index element={<NoMatch />} />
        </Routes>
      </Container>
      <Footer />
    </>
  );
};

Jurisdiction.propTypes = {};

export default Jurisdiction;
