import React, { useRef, useState, useEffect, useContext } from "react";

import { Link, useNavigate } from "react-router-dom";
import { get } from "utils/DeApi";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import ProducerCreate from "../ProducerCreate/ProducerCreate";
import { UserContext } from "contexts/UserProvider";
import { Alert } from "react-bootstrap";

const ProducerList = () => {
  const subscribedPromises = useRef([]);

  const [producers, setProducers] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const navigate = useNavigate();
  const user = useContext(UserContext);

  useEffect(() => {
    const fetchProducers = () => {
      setError(null);
      setIsLoading(true);

      let producersPromise = get("producers");

      producersPromise.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);
          setProducers(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(producersPromise);
    };

    fetchProducers();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return (
    <>
      {user && user.isAdmin
        ? producers && (
            <span className="float-end">
              <ProducerCreate
                producers={producers}
                onProducerCreated={(producer) =>
                  navigate(`/producers/${producer.id}`)
                }
              />
            </span>
          )
        : " "}

      <h4 className="py-1">Producers</h4>
      {error && <ErrorHandler error={error} />}

      {isLoading ? (
        <Loader />
      ) : producers && producers.length > 0 ? (
        producers.map((producer) => {
          return (
            <div className="py-3 border-top" key={producer.id}>
              <span className="float-end text-center ms-2 ">
                <small>Jurisdictions</small>
                <br />
                <span className="fs-2 fw-bold">
                  {producer.jurisdictions.length || 0}
                </span>
              </span>{" "}
              <h4>
                <Link to={`producers/${producer.id}`}>{producer.name}</Link>
              </h4>
              <p className="text-muted mb-2">{producer.description}</p>
              <p className="mb-0 flex justify-between">
                <span className="flex-none border text-gray-600 text-xs tracking-wide px-2 py-1 rounded-md">
                  Updated on{" "}
                  {new Date(producer.updatedAt).toLocaleString([], {
                    dateStyle: "short",
                  })}
                  &nbsp; at &nbsp;
                  {new Date(producer.updatedAt).toLocaleString([], {
                    timeStyle: "short",
                  })}
                </span>
              </p>
            </div>
          );
        })
      ) : (
        <div className="border-top py-3">
          {user.isAdmin ? (
            <Alert variant="primary">No producers found in the system.</Alert>
          ) : (
            <Alert variant="primary">
              No producers found for this profile.
            </Alert>
          )}
        </div>
      )}
    </>
  );
};

export default ProducerList;
