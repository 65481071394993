import React, { useContext } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Helmet } from "react-helmet";

import Feedback from "../Feedback/Feedback";
import { UserContext } from "contexts/UserProvider";
import Notifications from "../Notifications/Notifications";

const Header = ({ title }) => {
  const user = useContext(UserContext);
  const pathname = window.location.pathname;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || ""}</title>
      </Helmet>
      <Navbar
        bg="white"
        variant="light"
        expand="lg"
        fixed="top"
        className="border-bottom MainHeader"
      >
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <img
              src="/logo.svg"
              className="d-inline-block align-top"
              alt="ERM"
            />{" "}
            EPRComplete
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="me-auto"></Nav>
            <Nav className="ms-auto">
              {user && user.isAdmin ? (
                <NavDropdown
                  className="tracking-wide"
                  align="end"
                  title="Manage"
                >
                  <NavDropdown.Item as={Link} to="/regulations">
                    Regulations
                  </NavDropdown.Item>{" "}
                  <NavDropdown.Item as={Link} to="/topics">
                    Topics & Requirements
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                " "
              )}
              {pathname.includes("producers") ? (
                <Notifications producerId={pathname.split("/").pop()} />
              ) : null}
              <Feedback />

              <NavDropdown align="end" title={`Hi, ${user?.firstName}`}>
                <NavDropdown.Item disabled>{user?.email}</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/logout">
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

Header.propTypes = {
  title: PropTypes.string,
};

export default Header;
