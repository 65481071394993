import React, { useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Formik } from "formik";

import { Modal, Button, Form, Alert } from "react-bootstrap";
import { destroy } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

const JurisdictionDelete = ({ jurisdiction, onJurisdictionDeleted }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    title: yup
      .string()
      .required("Required field")
      .oneOf([jurisdiction.title], "Must match title"),
  });

  const deleteJurisdiction = () => {
    setError(null);
    setIsLoading(true);
    const jurisdictionPromise = destroy(`jurisdictions/${jurisdiction.id}`);
    jurisdictionPromise.promise
      .then(() => {
        setError(null);
        setIsLoading(false);
        onJurisdictionDeleted(jurisdiction);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      <Button
        variant="outline-danger"
        className="w-100"
        size="sm"
        onClick={handleShow}
      >
        Delete Jurisdiction
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete this jurisdiction?
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          initialValues={{ title: "" }}
          onSubmit={(values) => deleteJurisdiction(values)}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form onKeyDown={(e) => e.stopPropagation()}>
              <Modal.Body>
                <Alert variant="secondary">
                  <small>{jurisdiction.title}</small>
                </Alert>

                <Form.Group controlId="productName" className="mt-3 mb-3">
                  <Form.Label>
                    Type the title of the jurisdiction to confirm.
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.title === jurisdiction.title}
                    isInvalid={errors.title && touched.title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>
              <Modal.Footer>
                <Button size="sm" variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  size="sm"
                  variant="danger"
                  disabled={!isValid}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

JurisdictionDelete.propTypes = {
  jurisdiction: PropTypes.object.isRequired,
  onJurisdictionDeleted: PropTypes.func.isRequired,
};

export default JurisdictionDelete;
