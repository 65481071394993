import React, { useEffect, useState } from "react";
import { Tab, Breadcrumb, Nav } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import RegulatoryRequirementTab from "./Tabs/RegulatoryRequirementTab/RegulatoryRequirementTab";
import SummaryTab from "./Tabs/Summary/SummaryTab";
import LegislationTab from "./Tabs/LegislationTab/LegislationTab";

const ProducerJurisdictionTopic = ({ producer, jurisdiction, topics }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(
    searchParams.get("tab") || "Summary"
  );

  const params = useParams();
  const topicId = params.topicId;
  const topic = topics.find((topic) => topic.id === topicId);

  useEffect(() => {
    setSearchParams({ tab: activeTab });
  }, [activeTab]);

  return (
    <div className="mt-3 mb-5">
      <Helmet>
        <title>{`${topic?.name} - ${activeTab}`}</title>
      </Helmet>

      <Breadcrumb className="">
        <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item href={`/producers/${producer?.id}`}>
          {producer?.name}
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href={`/producers/${producer?.id}/jurisdictions/${jurisdiction.id}`}
        >
          {jurisdiction.title}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {topic?.name} - {activeTab}
        </Breadcrumb.Item>
      </Breadcrumb>

      <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab}>
        <Nav variant="tabs" className="">
          <Nav.Item
            onClick={() => setActiveTab("Summary")}
            className="cursor-pointer"
          >
            <Nav.Link
              onClick={() => setActiveTab("Summary")}
              eventKey="Summary"
            >
              Summary Review
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="cursor-pointer">
            <Nav.Link
              onClick={() => setActiveTab("Legislation")}
              eventKey="Legislation"
            >
              Legislations
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="cursor-pointer">
            <Nav.Link
              onClick={() => setActiveTab("Regulatory Requirements")}
              eventKey="Regulatory Requirements"
            >
              Regulatory Requirements
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content className="">
          <Tab.Pane eventKey="Summary">
            <SummaryTab topic={topic} jurisdiction={jurisdiction} />
          </Tab.Pane>
          <Tab.Pane eventKey="Legislation">
            <LegislationTab topic={topic} jurisdiction={jurisdiction} />
          </Tab.Pane>
          <Tab.Pane eventKey="Regulatory Requirements">
            <RegulatoryRequirementTab
              topic={topic}
              jurisdiction={jurisdiction}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default ProducerJurisdictionTopic;
