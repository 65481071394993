import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import { Modal, Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";

import { post } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const RequirementCreate = ({
  jurisdiction,
  question,
  onRequirementCreated,
}) => {
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    reqs: yup.string().min(2, "Too Short!").max(1000, "Too Long!").required(),
  });

  const createRequirement = ({ reqs }) => {
    setError(null);
    setIsLoading(true);

    const requirementPromise = post(
      `topic-questions/${question.id}/regulatory-requirements`,
      {
        jurisdictionId: jurisdiction.id,
        response: reqs,
      }
    );

    requirementPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onRequirementCreated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(requirementPromise);
  };

  return (
    <>
      <Button
        variant="secondary"
        className="py-0"
        onClick={handleShow}
        size="sm"
      >
        Edit
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Requirement</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => createRequirement(values)}
          initialValues={{
            reqs: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="summary" className="mt-3 mb-3">
                  <Form.Label>{question.prompt}</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="reqs"
                    placeholder="Your may enter the regulations requirements here."
                    value={values.reqs}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={5}
                    isValid={values.reqs}
                    isInvalid={errors.reqs && touched.reqs}
                  />
                  <Form.Text className="text-muted">
                    {1000 - values.reqs.length} characters left
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.reqs}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="secondary"
                  size="sm"
                  disabled={!isValid}
                >
                  Add Requirement
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

RequirementCreate.propTypes = {
  question: PropTypes.object.isRequired,
  jurisdiction: PropTypes.object.isRequired,
  onRequirementCreated: PropTypes.func.isRequired,
};

export default RequirementCreate;
