import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";

import { put } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const LegislationUpdate = ({ legislation, onLegislationUpdated }) => {
  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    name: yup.string().min(2, "Too Short!").max(100, "Too Long!").required(),
    link: yup.string().url().required(),
    notes: yup
      .string()
      .max(2000, "Characters should not exceed 2000!")
      .nullable(),
  });

  const updateLegislation = ({ name, link, notes, reviewScheduled }) => {
    setError(null);
    setIsLoading(true);

    const legislationPromise = put(`legislations/${legislation.id}`, {
      name: name,
      link: link,
      notes: notes,
      reviewScheduled: reviewScheduled,
    });

    legislationPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onLegislationUpdated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(legislationPromise);
  };

  return (
    <>
      <Button variant="secondary" onClick={handleShow} size="sm">
        Edit
      </Button>
      <Modal show={show} backdrop={"static"} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Update Legislation <i>{legislation.name}</i>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updateLegislation(values)}
          initialValues={{
            name: legislation.name,
            link: legislation.link,
            notes: legislation.notes,
            reviewScheduled: legislation.reviewScheduled
              ? new Date(legislation.reviewScheduled)
                  .toISOString()
                  .substring(0, 10)
              : "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="name" className="my-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.name}
                    isInvalid={errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="link" className="my-3">
                  <Form.Label>Link</Form.Label>
                  <Form.Control
                    type="text"
                    name="link"
                    value={values.link}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.link}
                    isInvalid={errors.link && touched.link}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.link}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="notes" className="mt-3 mb-3">
                  <Form.Label>
                    Notes{" "}
                    <small>
                      <i>Optional</i>
                    </small>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="notes"
                    placeholder="Your may enter your notes and comments here."
                    value={values.notes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={6}
                    isValid={values.notes}
                    isInvalid={errors.notes && touched.notes}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.notes}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="link" className="my-3">
                  <Form.Label>Review Scheduled</Form.Label>
                  <Form.Control
                    type="date"
                    name="reviewScheduled"
                    value={values.reviewScheduled}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.reviewScheduled}
                    isInvalid={
                      errors.reviewScheduled && touched.reviewScheduled
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.reviewScheduled}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" size="sm" disabled={!isValid}>
                  Update Legislation
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

LegislationUpdate.propTypes = {
  legislation: PropTypes.object.isRequired,
  onLegislationUpdated: PropTypes.func.isRequired,
};

export default LegislationUpdate;
