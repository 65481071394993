import React from "react";
import { useState, useEffect, useRef } from "react";
import { get } from "utils/DeApi";
import Requirement from "../Requirement/Requirement";

const TopicQuestionDetails = ({ section, jurisdiction }) => {
  const subscribedPromises = useRef([]);

  const [topicQuestions, setTopicQuestions] = useState([]);

  useEffect(() => {
    const fetchTopicQuestions = () => {
      let topicQuestionsPromise = get(`sections/${section.id}/topic-questions`);
      // setIsLoading(true)
      // setError(null)

      topicQuestionsPromise.promise
        .then((response) => {
          // setIsLoading(false)
          setTopicQuestions(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            console.warn(error);
            // setError(error);
            // setIsLoading(false);
          }
        });
      subscribedPromises.current.push(fetchTopicQuestions);
    };

    fetchTopicQuestions();
  }, [section]);

  const renderTopicQuestions = () => {
    return topicQuestions.map((question) => (
      <tr key={question.id}>
        <td>{question.prompt}</td>
        <Requirement question={question} jurisdiction={jurisdiction} />
      </tr>
    ));
  };

  return (
    <>
      <tr>
        <td
          colSpan="4"
          className="uppercase font-semibold tracking-wide bg-primary bg-opacity-10 py-3"
        >
          {section.name}
        </td>
      </tr>
      {topicQuestions.length > 0 ? renderTopicQuestions() : null}
    </>
  );
};

export default TopicQuestionDetails;
