import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as yup from "yup";
import { Form, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { put } from "utils/DeApi";

const UpdateTopicQuestion = ({ question, onUpdated }) => {
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  const updateTopicQuestion = (values) => {
    const questionPromise = put(`topic-questions/${question.id}`, values);
    setIsLoading(true);
    setError(null);

    questionPromise.promise
      .then((response) => {
        setIsLoading(false);
        onUpdated(response.data);
        handleClose();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(questionPromise);
  };

  const schema = yup.object().shape({
    prompt: yup.string().min(2, "Too Short!").max(1000, "Too Long!").required(),
    description: yup.string().max(1000, "Too Long!").nullable(),
    info: yup.string().nullable().min(5, "That id is too short!"),
  });

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="button-tooltip-2">Edit</Tooltip>}
      >
        <Button variant="secondary" size="sm" onClick={handleShow}>
          Edit
        </Button>
      </OverlayTrigger>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update Topic Question</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updateTopicQuestion(values)}
          initialValues={{
            prompt: question.prompt || "",
            description: question.description || "",
            info: question.info || "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form onKeyDown={(e) => e.stopPropagation()}>
              <Modal.Body>
                <Form.Group className="mb-3" controlId="prompt">
                  <Form.Label>Prompt</Form.Label>
                  <Form.Control
                    name="prompt"
                    value={values.prompt}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    as="textarea"
                    rows={3}
                    isValid={values.prompt}
                    isInvalid={errors.prompt && touched.prompt}
                  />
                  <Form.Text className="text-muted">
                    {1000 - values.prompt.length} characters left
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.prompt}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    as="textarea"
                    rows={3}
                    isValid={values.description}
                    isInvalid={errors.description && touched.description}
                  />
                  <Form.Text className="text-muted">
                    {1000 - values.description.length} characters left
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="info">
                  <Form.Label>Info</Form.Label>
                  <Form.Control
                    name="info"
                    value={values.info}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.info}
                    isInvalid={errors.info && touched.info}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.info}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>
              <Modal.Footer>
                <Button size="sm" variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  size="sm"
                  variant="primary"
                  disabled={!isValid}
                >
                  Update Topic Question
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

UpdateTopicQuestion.propTypes = {
  question: PropTypes.object.isRequired,
};

export default UpdateTopicQuestion;
