import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";

import { put } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const ReviewUpdate = ({ review, onReviewUpdated }) => {
  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    summary: yup
      .string()
      .min(2, "Too Short!")
      .max(10000, "Maximum 10000 characters allowed!")
      .required(),
  });

  const updateReview = ({ summary }) => {
    setError(null);
    setIsLoading(true);

    const reviewPromise = put(`reviews/${review.id}`, {
      summary: summary,
    });

    reviewPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onReviewUpdated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(reviewPromise);
  };

  return (
    <>
      <Button variant="secondary" onClick={handleShow} size="sm">
        Edit
      </Button>
      <Modal show={show} backdrop={"static"} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Update Review <i>{review.name}</i>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updateReview(values)}
          initialValues={{
            summary: review.summary,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="summary" className="mt-3 mb-3">
                  <Form.Control
                    as="textarea"
                    name="summary"
                    placeholder="Your may enter your regulations review, summary or comments here."
                    value={values.summary}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={7}
                    isValid={values.summary && touched.summary}
                    isInvalid={errors.summary && touched.summary}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.summary}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" size="sm" disabled={!isValid}>
                  Update Review
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

ReviewUpdate.propTypes = {
  review: PropTypes.object.isRequired,
  onReviewUpdated: PropTypes.func.isRequired,
};

export default ReviewUpdate;
