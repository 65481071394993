import React, { useState, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik, Field } from "formik";

import { post } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const JurisdictionCreate = ({ jurisdictions, onJurisdictionCreated }) => {
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    title: yup
      .string()
      .min(2, "Too Short!")
      .max(100, "Too Long!")
      .required()
      .notOneOf(
        jurisdictions.map((jurisdiction) => jurisdiction.title),
        "A jurisdiction with the same title already exists"
      ),

    name: yup
      .string()
      .min(2, "Too Short!")
      .max(100, "Too Long!")
      .required()
      .notOneOf(
        jurisdictions.map((jurisdiction) => jurisdiction.name),
        "A jurisdiction with the same title already exists"
      ),

    category: yup.string(),
    ermCoverage: yup.boolean(),
  });

  const createJurisdiction = ({ name, title, category, ermCoverage }) => {
    setError(null);
    setIsLoading(true);

    const jurisdictionPromise = post(`/jurisdictions`, {
      name: name,
      title: title,
      category: category,
      ermCoverage: ermCoverage,
    });

    jurisdictionPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onJurisdictionCreated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(jurisdictionPromise);
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow} size="sm">
        New Jurisdiction
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>New Jurisdiction</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => createJurisdiction(values)}
          initialValues={{
            name: "",
            title: "",
            category: "country",
            ermCoverage: true,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="jurisdictionTitle" className="my-3">
                  <Form.Label>Jurisdiction Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.title}
                    isInvalid={errors.title && touched.title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="jurisdictionName" className="my-3">
                  <Form.Label>Jurisdiction Name (code)</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.name}
                    isInvalid={errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="lens">
                  <Form.Label>Select Category</Form.Label>
                  <Field
                    as={Form.Select}
                    name="category"
                    value={values.category}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.category}
                  >
                    <option key={"global"} value={"global"}>
                      Global
                    </option>
                    <option key={"country"} value={"country"}>
                      Country
                    </option>
                    <option key={"state"} value={"state"}>
                      State
                    </option>
                    <option key={"province"} value={"province"}>
                      Province
                    </option>
                    <option key={"region"} value={"region"}>
                      Region
                    </option>
                  </Field>
                </Form.Group>
                {/* <Form.Group className="d-inline-flex mt-3">
                  <Form.Label className="me-2">ERM Coverage</Form.Label>

                  <Field
                    as={Form.Switch}
                    onChange={handleChange}
                    checked={values.ermCoverage}
                    value={values.ermCoverage}
                    type="switch"
                    name="ermCoverage"
                  />
                </Form.Group> */}

                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="secondary"
                  size="sm"
                  disabled={!isValid}
                >
                  Create Jurisdiction
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default JurisdictionCreate;
