import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import React, { useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";
import { ListGroup, Card } from "react-bootstrap";

const LegislationTab = ({ topic, jurisdiction }) => {
  const subscribedPromises = useRef([]);

  const [isLoading, setIsLoading] = useState(false);
  const [legislations, setLegislations] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchLegislations = () => {
      setError(null);
      setIsLoading(true);
      const legislationsPromise = get(
        `jurisdictions/${jurisdiction.id}/legislations`,
        { params: { topicId: topic.id } }
      );
      legislationsPromise.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);
          setLegislations(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(legislationsPromise);
    };

    fetchLegislations();
  }, [jurisdiction, topic]);

  return (
    <Card className="mb-5 border-t-0">
      <Card.Body>
        {error && <ErrorHandler error={error} />}
        {isLoading ? (
          <Loader />
        ) : (
          <ListGroup variant="flush">
            {legislations &&
              legislations.map((legislation, index) => (
                <ListGroup.Item key={legislation.id} className="px-0">
                  <p className="flex justify-between">
                    <span className="mt-2 tracking-wide">
                      <a
                        className=""
                        target="_blank"
                        rel="noreferrer"
                        href={legislation.link}
                      >
                        {legislation.name}
                      </a>
                    </span>
                    <span className="space-x-2">
                      <span className="flex-none mt-2 border text-gray-600 text-xs tracking-wide font-semibold px-2 py-1 rounded-md">
                        Updated on{" "}
                        {new Date(legislation.updatedAt).toLocaleString([], {
                          dateStyle: "short",
                        })}
                        &nbsp; at &nbsp;
                        {new Date(legislation.updatedAt).toLocaleString([], {
                          timeStyle: "short",
                        })}
                      </span>
                      {legislation.reviewScheduled ? (
                        <span className="flex-none border border-secondary text-gray-600 text-xs tracking-wide font-semibold px-2 py-1 rounded-md">
                          Review scheduled at{" "}
                          {new Date(legislation.reviewScheduled).toLocaleString(
                            [],
                            {
                              dateStyle: "short",
                            }
                          )}
                        </span>
                      ) : (
                        <span className="flex-none border border-secondary text-gray-600 text-xs tracking-wide font-semibold px-2 py-1 rounded-md">
                          No Review Scheduled
                        </span>
                      )}
                    </span>
                  </p>
                  <p>{legislation.notes}</p>
                </ListGroup.Item>
              ))}
          </ListGroup>
        )}
      </Card.Body>
    </Card>
  );
};

export default LegislationTab;
