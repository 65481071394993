import React, { useEffect, useState, useRef } from "react";
import { Row, Col, ProgressBar, ListGroup, Alert, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { get } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import { get as beeGet } from "utils/BeeApi";

const JurisdictionOverview = ({ topics, jurisdiction }) => {
  const subscribedPromises = useRef([]);

  const [summary, setSummary] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNews, setIsLoadingNews] = useState(false);
  const [error, setError] = useState(null);
  const [ermContents, setErmContents] = useState([]);
  const [ermContentSpecificInfo, setContentSpecificInfo] = useState([]);

  useEffect(() => {
    const getContents = () => {
      setIsLoadingNews(true);
      const categoryId = process.env.REACT_APP_CONTENT_CATEGORY_ID;
      const jurisdictionContentsPromise = beeGet(
        `search/contents?q=categories.category_id:${categoryId}`
      );

      jurisdictionContentsPromise.promise
        .then((response) => {
          const refinedData = response.data
            .filter((content) => content.listed === true)
            .map(({ contentId }) => ({
              contentId,
            }));
          setErmContents(refinedData);
          setIsLoadingNews(false);
        })
        .catch((error) => {
          setError(error);
          setIsLoadingNews(false);
        });
    };

    const fetchTopicDashboardSummary = (topic, jurisdiction) => {
      const dashboardPromise = get(
        `jurisdictions/${jurisdiction.id}/topics/${topic.id}/dashboard`
      );
      setIsLoading(true);
      setError(null);

      dashboardPromise.promise
        .then((response) => {
          let dt = {
            id: topic.id,
            name: topic.name,
            questions: response.data.topicQuestions,
            requirements: response.data.requirements,
          };
          setSummary((summary) => [dt, ...summary]);
          setReviews((reviews) => [...response.data.reviews, ...reviews]);
          setIsLoading(false);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            console.warn(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(dashboardPromise);
    };

    topics.map((topic) => fetchTopicDashboardSummary(topic, jurisdiction));

    getContents();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [topics, jurisdiction]);

  useEffect(() => {
    const getContentSpecificInfo = () => {
      ermContents.forEach((ermContent) => {
        const jurisdictionSpecificContentPromise = beeGet(
          `contents/${ermContent?.contentId}`
        );

        jurisdictionSpecificContentPromise.promise
          .then((response) => {
            setContentSpecificInfo((prevState) => [
              ...prevState,
              response.data,
            ]);
          })
          .catch((error) => {
            setError(error);
          });
      });
    };

    getContentSpecificInfo();
  }, [ermContents]);

  useEffect(() => {
    const sortErmSpecificInfo = () => {
      ermContentSpecificInfo.sort((a, b) =>
        !!b.contentExternalLink
          ? a.contentExternalLink > b.contentExternalLink
            ? 1
            : -1
          : true
      );
    };
    sortErmSpecificInfo();
  }, [ermContentSpecificInfo]);

  function getTopicQuestions(topic) {
    let topicSummary = summary.find((item) => item.id === topic.id);
    return topicSummary;
  }

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  const sortedReviews = reviews.sort(function (a, b) {
    return new Date(b.updatedAt) - new Date(a.updatedAt);
  });

  return (
    <Row>
      <Col xs={12} md={8} className="py-4">
        <h2>Overview</h2>

        <Row className="text-nowrap my-4 gx-2">
          {topics &&
            topics.map((topic) => (
              <Col md={4} className="" key={topic.id}>
                <Card key={topic.id}>
                  <Card.Body>
                    <h5 className="mb-2">{topic.name} Completion</h5>
                    <hr />
                    <p className="mb-2">
                      <small>
                        {getTopicQuestions(topic)?.requirements.length || 0} of{" "}
                        {getTopicQuestions(topic)?.questions.length || 0}{" "}
                        Requirements
                      </small>
                    </p>
                    <ProgressBar
                      now={getTopicQuestions(topic)?.requirements.length || 0}
                      max={getTopicQuestions(topic)?.questions.length || 0}
                    />

                    <h6 className="mt-3">
                      <Link
                        to={`/jurisdictions/${jurisdiction.id}/topics/${topic.id}/summary`}
                        className="text-uppercase text-decoration-none"
                      >
                        Review{" "}
                        <span className="material-icons-outlined md-16">
                          east
                        </span>
                      </Link>
                    </h6>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
        <div className="my-4">
          <h3>Latest {jurisdiction.title} Reviews</h3>
          <hr />

          {sortedReviews.length > 0 ? (
            <Card>
              <Card.Body>
                <ListGroup variant="flush">
                  {sortedReviews.map((review) => (
                    <ListGroup.Item key={review.id}>
                      {review.summary} <br />
                      <span className="mt-1 flex justify-between">
                        <span className="flex-none border text-gray-600 text-xs tracking-wide font-semibold px-2 py-1 rounded-md">
                          {review.topic.name}
                        </span>
                        <span className="flex-none border text-gray-600 text-xs tracking-wide font-semibold px-2 py-1 rounded-md">
                          Updated on{" "}
                          {new Date(review.updatedAt).toLocaleString([], {
                            dateStyle: "short",
                          })}
                          &nbsp; at &nbsp;
                          {new Date(review.updatedAt).toLocaleString([], {
                            timeStyle: "short",
                          })}
                        </span>
                      </span>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          ) : (
            <Alert variant="info">
              We could not find any reviews for this jurisdiction
            </Alert>
          )}
        </div>
      </Col>
      <Col xs={12} md={4} className="scroller py-4">
        <h4>News</h4>
        <small>EPR-related news and articles</small>
        <hr />
        {isLoadingNews ? (
          <Loader />
        ) : (
          ermContentSpecificInfo &&
          ermContentSpecificInfo.map((ermArticle) =>
            ermArticle.contentExternalLink ? (
              <a
                href={ermArticle?.contentExternalLink}
                target="_blank"
                rel="noreferrer"
                key={ermArticle?.contentId}
              >
                <p>
                  <div className="flex">
                    <div>
                      <span className="float-start material-icons-outlined md-20 me-2">
                        article
                      </span>
                    </div>
                    <div>{ermArticle?.title}</div>
                  </div>
                </p>
              </a>
            ) : (
              <a
                href={`/contents/${ermArticle.contentId}`}
                rel="noreferrer"
                key={ermArticle?.contentId}
              >
                <p>
                  <div className="flex">
                    <div>
                      <span className="float-start material-icons-outlined md-20 me-2">
                        article
                      </span>
                    </div>
                    <div>{ermArticle?.title}</div>
                  </div>
                </p>
              </a>
            )
          )
        )}
      </Col>
    </Row>
  );
};

export default JurisdictionOverview;
