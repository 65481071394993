import React from "react";
import PropTypes from "prop-types";
import { ListGroup, ButtonGroup } from "react-bootstrap";
import UpdateTopicQuestion from "../UpdateTopicQuestion/UpdateTopicQuestion";
import DeleteTopicQuestion from "../DeleteTopicQuestion/DeleteTopicQuestion";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const DraggableTopicQuestion = ({
  topicQuestion,
  onTopicQuestionUpdated,
  onTopicQuestionDeleted,
  className,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: topicQuestion?.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: isDragging ? "#f1f5f9" : "",
  };

  return (
    <ListGroup.Item
      id="DraggableItem"
      className={className ? className : "px-0"}
      ref={setNodeRef}
      style={style}
    >
      <div className="relative flex">
        <div className="mr-2">
          <span
            className="DragHandle material-icons-outlined text-gray-400 hover:cursor-move"
            {...listeners}
            {...attributes}
          >
            drag_indicator
          </span>
        </div>
        <div>
          <div className="mb-2">
            <span>{topicQuestion.prompt}</span>
          </div>

          <div className="flex-col mt-auto">
            <span className="flex-none mt-2 border text-gray-600 text-xs tracking-wide px-2 py-1 rounded-md">
              Updated at{" "}
              {new Date(topicQuestion.updatedAt).toLocaleString([], {
                dateStyle: "short",
                timeStyle: "short",
              })}
            </span>
          </div>
        </div>
        <div className="absolute bottom-0 right-0">
          <ButtonGroup className="" aria-label="First group">
            <UpdateTopicQuestion
              question={topicQuestion}
              onUpdated={onTopicQuestionUpdated}
            />
            <DeleteTopicQuestion
              question={topicQuestion}
              onDelete={onTopicQuestionDeleted}
            />
          </ButtonGroup>
        </div>
      </div>
    </ListGroup.Item>
  );
};

DraggableTopicQuestion.propTypes = {
  topicQuestion: PropTypes.object.isRequired,
  onTopicQuestionUpdated: PropTypes.func.isRequired,
  onTopicQuestionDeleted: PropTypes.func.isRequired,
};

export default DraggableTopicQuestion;
