import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import { get, put } from "utils/DeApi";
import { Row, Col, Alert, Card, ListGroup } from "react-bootstrap";
import AddTopicQuestion from "./AddTopicQuestion/AddTopicQuestion";
import DraggableTopicQuestion from "./DraggableTopicQuestion/DraggableTopicQuestion";

import {
  DndContext,
  closestCenter,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

const SectionDetails = () => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [questions, setQuestions] = useState([]);
  const [section, setSection] = useState({});
  const [activeTopicQuestion, setActiveTopicQuestion] = useState(null);

  const { sectionId } = useParams();

  const sensors = useSensors(
    useSensor(MyPointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    const fetchSection = () => {
      setIsLoading(true);
      setError(null);

      const getSectionPromise = get(`sections/${sectionId}`);

      getSectionPromise.promise
        .then((response) => {
          setSection(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(getSectionPromise);
    };

    fetchSection();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [sectionId]);

  useEffect(() => {
    const fetchQuestions = () => {
      setIsLoading(true);
      setError(null);

      const getQuestionsPromise = get(`sections/${sectionId}/topic-questions`);

      getQuestionsPromise.promise
        .then((response) => {
          setQuestions(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(getQuestionsPromise);
    };

    fetchQuestions();
  }, [sectionId]);

  const handleTopicQuestionDeleted = (question) => {
    setQuestions((questions) => [
      ...questions.filter((qn) => qn.id !== question.id),
    ]);
  };

  const handleTopicQuestionUpdated = (question) => {
    setQuestions((questions) => [
      ...questions.map((qn) => (qn.id === question.id ? question : qn)),
    ]);
  };

  const handleQuestionCreated = (question) => {
    setQuestions((questions) => [...questions, question]);
  };

  const handleDragStarted = (event) => {
    const { active } = event;
    const topicQuestion = questions.find((item) => item.id === active.id);

    setActiveTopicQuestion(topicQuestion);
  };

  const handleDragEnded = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setQuestions((sections) => {
        const oldIndex = sections.findIndex((item) => {
          return item.id === active.id;
        });
        const newIndex = sections.findIndex((item) => {
          return item.id === over.id;
        });
        return arrayMove(sections, oldIndex, newIndex);
      });

      const index = questions.findIndex((item) => {
        return item.id === over.id;
      });
      updateTopicQuestionsOrder(index, active.id);
    }

    setActiveTopicQuestion(null);
  };

  const updateTopicQuestionsOrder = (index, sectionId) => {
    const updatePromise = put(`topic-questions/${sectionId}/order`, {
      current: index + 1,
    });

    updatePromise.promise
      .then((response) => {})
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
        }
      });
  };

  return (
    <Row>
      <Col xs={12} className="py-4">
        <h1>{section.name}</h1>
        <p>{section.description}</p>
        <p>
          <span className="flex-none mt-2 border text-gray-600 text-xs tracking-wide px-2 py-1 rounded-md">
            Updated at{" "}
            {new Date(section.updatedAt).toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </span>
        </p>
        <hr />

        <Card>
          <Card.Body>
            <div className="flex justify-between border-bottom">
              <h3>Topic Questions</h3>
              <div className="">
                <AddTopicQuestion
                  section={section}
                  onCreated={handleQuestionCreated}
                  questions={questions.map((key) => key.prompt)}
                />
              </div>
            </div>

            {error && <ErrorHandler error={error} />}

            {isLoading ? (
              <Loader />
            ) : questions &&
              Array.isArray(questions) &&
              questions.length > 0 ? (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStarted}
                onDragEnd={handleDragEnded}
              >
                <ListGroup variant="flush">
                  <>
                    <SortableContext
                      items={questions}
                      strategy={verticalListSortingStrategy}
                    >
                      {questions &&
                        questions.map((question) => (
                          <DraggableTopicQuestion
                            key={question.id}
                            id={question.id}
                            topicQuestion={question}
                            onTopicQuestionUpdated={handleTopicQuestionUpdated}
                            onTopicQuestionDeleted={handleTopicQuestionDeleted}
                          />
                        ))}
                    </SortableContext>
                    <DragOverlay>
                      {activeTopicQuestion ? (
                        <DraggableTopicQuestion
                          className="bg-green-50 px-0"
                          id={activeTopicQuestion.id}
                          topicQuestion={activeTopicQuestion}
                          onTopicQuestionUpdated={handleTopicQuestionUpdated}
                          onTopicQuestionDeleted={handleTopicQuestionDeleted}
                        />
                      ) : null}
                    </DragOverlay>
                  </>
                </ListGroup>
              </DndContext>
            ) : (
              <Alert variant="info" className="mt-3">
                No topic questions created for this section.{" "}
                <AddTopicQuestion
                  section={section}
                  onCreated={handleQuestionCreated}
                  isLink={true}
                />
              </Alert>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default SectionDetails;

class MyPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: "onPointerDown",
      handler: ({ nativeEvent: event }) => {
        if (
          !event.isPrimary ||
          event.button !== 0 ||
          isInteractiveElement(event.target)
        ) {
          return false;
        }

        return true;
      },
    },
  ];
}

function isInteractiveElement(element) {
  const interactiveElements = [
    "button",
    "input",
    "textarea",
    "select",
    "option",
  ];

  if (interactiveElements.includes(element.tagName.toLowerCase())) {
    return true;
  }

  return false;
}
