import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Button,
  Form,
  Alert,
  OverlayTrigger,
  Tooltip,
  Badge,
} from "react-bootstrap";
import { destroy, get } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import { Formik } from "formik";
import * as yup from "yup";

const DeleteTopicQuestion = ({ question, onDelete }) => {
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [regulatoryRequirements, setRegulatoryRequirements] = useState();

  const fetchRegulatoryRequirements = () => {
    const fetchPromise = get(
      `topic-questions/${question.id}/regulatory-requirements`
    );
    setIsLoading(true);

    fetchPromise.promise
      .then((response) => {
        setRegulatoryRequirements(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    fetchRegulatoryRequirements();
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  const deleteQuestion = () => {
    const deletePromise = destroy(`topic-questions/${question.id}`);
    setIsLoading(true);
    setError(null);

    deletePromise.promise
      .then((response) => {
        setIsLoading(false);
        handleClose();
        onDelete(question);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(deletePromise);
  };

  const schema = yup.object().shape({
    confirm: yup.string().required("Required field").oneOf(["delete"]),
  });

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="button-tooltip-2">Delete</Tooltip>}
      >
        <Button variant="outline-secondary" size="sm" onClick={handleShow}>
          Del
        </Button>
      </OverlayTrigger>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete this topic question?
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          initialValues={{ confirm: "" }}
          onSubmit={(values) => deleteQuestion(values)}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            isValid,
            errors,
            touched,
          }) =>
            isLoading ? (
              <Loader />
            ) : (
              <Form onKeyDown={(e) => e.stopPropagation()}>
                <Modal.Body>
                  <Alert variant="dark">
                    <small className="text-danger">{question.prompt}</small>
                    <br />
                    <br />
                    <small>
                      This will delete the attached{" "}
                      <Badge variant="primary">
                        {regulatoryRequirements &&
                          regulatoryRequirements.length}{" "}
                        regulatory requirements
                      </Badge>{" "}
                      as well, are you sure?
                    </small>
                  </Alert>

                  <Form.Group controlId="productName" className="mt-3 mb-3">
                    <Form.Label>
                      Type <pre className="d-inline text-danger">delete</pre> to
                      confirm.
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="confirm"
                      value={values.confirm}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={values.confirm === "delete"}
                      isInvalid={errors.confirm && touched.confirm}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.confirm}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {error && <ErrorHandler error={error} />}
                  {isLoading && <Loader />}
                </Modal.Body>
                <Modal.Footer>
                  <Button size="sm" variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    size="sm"
                    variant="danger"
                    disabled={!isValid}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Formik>
      </Modal>
    </>
  );
};

DeleteTopicQuestion.propTypes = {
  question: PropTypes.object.isRequired,
};

export default DeleteTopicQuestion;
