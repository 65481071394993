import React from "react";

import { Col, Row, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

const ProducerDashboard = () => {
  return (
    <Row className="justify-content-center py-4">
      <Col xs={12} md={9} className="my-3">
        <span className="float-end">
          <Button size="sm" variant="primary">
            New Producer
          </Button>
        </span>
        <h1>Producers</h1>
        <Form className="d-flex flex-row">
          <Form.Control
            type="search"
            placeholder="Find by name "
            aria-label="Search"
          />
        </Form>
        <hr />

        {
          <>
            <div className="my-3 border-bottom">
              <span className="float-end text-center ms-2">
                <small>Jurisdictions</small>
                <br />
                <span className="fs-2 fw-bold">2</span>
              </span>{" "}
              <h4>
                <Link to="/producers/1">Peloton</Link>
              </h4>
              <p>
                <small>
                  Updated on{" "}
                  {new Date().toLocaleString([], {
                    dateStyle: "short",
                  })}
                  &nbsp; at &nbsp;
                  {new Date().toLocaleString([], {
                    timeStyle: "short",
                  })}
                </small>
              </p>
            </div>
            <div className="my-3 border-bottom">
              <span className="float-end text-center ms-2">
                <small>Jurisdictions</small>
                <br />
                <span className="fs-2 fw-bold">1</span>
              </span>
              <h4>
                <Link to="/producers/1">Circle Petroleum</Link>
              </h4>
              <p>
                <small>
                  Updated on{" "}
                  {new Date().toLocaleString([], {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}
                </small>
              </p>
            </div>
            <div className="my-3 border-bottom">
              <span className="float-end text-center ms-2">
                <small>Jurisdictions</small>
                <br />
                <span className="fs-2 fw-bold">5</span>
              </span>
              <h4>
                <Link to="/producers/1">General Motors CRP56</Link>
              </h4>
              <p>
                <small>
                  Updated on{" "}
                  {new Date().toLocaleString([], {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}
                </small>
              </p>
            </div>
            <div className="my-3 border-bottom">
              <span className="float-end text-center ms-2">
                <small>Jurisdictions</small>
                <br />
                <span className="fs-2 fw-bold">2</span>
              </span>{" "}
              <h4>
                <Link to="/producers/1">Abbot</Link>
              </h4>
              <p>
                <small>
                  Updated on{" "}
                  {new Date().toLocaleString([], {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}
                </small>
              </p>
            </div>
          </>
        }
      </Col>
    </Row>
  );
};

export default ProducerDashboard;
